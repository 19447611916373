import { proxy } from "valtio"
import { AuthStore } from "./AuthStore"
import { TimberEyeStore } from "./TimberEyeStore"

class Store {
    readonly timbereye = proxy(new TimberEyeStore())
    readonly auth = proxy(new AuthStore())
}

const store = new Store()

export default store
