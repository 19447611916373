import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Byte: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: AccountStatus;
};

export type AccountConnection = {
  __typename?: 'AccountConnection';
  nodes: Array<Maybe<Account>>;
  pageInfo: PageInfo;
};

export type AccountConnectionFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<AccountStatus>>;
};

export type AccountConnectionInput = {
  accountConnectionFilters?: InputMaybe<AccountConnectionFilters>;
  connection: ConnectionInput;
};

export type AccountStatus =
  | 'ACTIVE'
  | 'DISABLED';

export type AddCommodityInstancesInput = {
  bookingContainerId: Scalars['String']['input'];
  fingerprints: Array<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
};

export type Bank = {
  __typename?: 'Bank';
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  account: Account;
  accountNumber: Scalars['String']['output'];
  archivedAt?: Maybe<Scalars['String']['output']>;
  bank: Bank;
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  routingNumber: Scalars['String']['output'];
};

export type BankAccountConnection = {
  __typename?: 'BankAccountConnection';
  nodes: Array<Maybe<BankAccount>>;
  pageInfo: PageInfo;
};

export type BankAccountConnectionFilters = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type BankAccountConnectionInput = {
  bankAccountConnectionFilters?: InputMaybe<BankAccountConnectionFilters>;
  connection: ConnectionInput;
};

export type BankConnection = {
  __typename?: 'BankConnection';
  nodes: Array<Maybe<Bank>>;
  pageInfo: PageInfo;
};

export type BankConnectionFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BankConnectionInput = {
  bankConnectionFilters?: InputMaybe<BankConnectionFilters>;
  connection: ConnectionInput;
};

export type Booking = {
  __typename?: 'Booking';
  aesCode?: Maybe<Scalars['String']['output']>;
  billOfLadingNumber?: Maybe<Scalars['String']['output']>;
  completeContainerCount: Scalars['Int']['output'];
  containerCount: Scalars['Int']['output'];
  createdTime: Scalars['String']['output'];
  destinationPort?: Maybe<TransportLocation>;
  documentCutoffDate: Scalars['String']['output'];
  documentation?: Maybe<BookingDocumentation>;
  earliestReturnDate: Scalars['String']['output'];
  emptyPickupLocationDescription: Scalars['String']['output'];
  estimatedDepartureDate: Scalars['String']['output'];
  externalBookingNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  originPort?: Maybe<TransportLocation>;
  portCutoffDate: Scalars['String']['output'];
  status: BookingStatus;
  steamshipLine: SteamshipLine;
  vessel: Scalars['String']['output'];
  voyage: Scalars['String']['output'];
};

export type BookingConnection = {
  __typename?: 'BookingConnection';
  nodes: Array<Maybe<Booking>>;
  pageInfo: PageInfo;
};

export type BookingConnectionFilters = {
  bookingStatuses?: InputMaybe<Array<BookingStatus>>;
  destinationPortIds?: InputMaybe<Array<Scalars['String']['input']>>;
  earliestReturnDate?: InputMaybe<Scalars['String']['input']>;
  externalBookingNumber?: InputMaybe<Scalars['String']['input']>;
  originPortIds?: InputMaybe<Array<Scalars['String']['input']>>;
  steamshipLines?: InputMaybe<Array<SteamshipLine>>;
};

export type BookingConnectionInput = {
  bookingConnectionFilters?: InputMaybe<BookingConnectionFilters>;
  connection: ConnectionInput;
};

export type BookingContainer = {
  __typename?: 'BookingContainer';
  booking?: Maybe<Booking>;
  chassis?: Maybe<Chassis>;
  commodityCategory: CommodityCategory;
  commodityCategoryDisplayString: Scalars['String']['output'];
  commodityCategoryOverlayDescription: Scalars['String']['output'];
  createdTime: Scalars['String']['output'];
  currentLocation?: Maybe<TransportLocation>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<BookingContainerImage>>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  loads?: Maybe<Array<Maybe<Load>>>;
  order?: Maybe<Order>;
  seal?: Maybe<Scalars['String']['output']>;
  shippingContainerNumber: Scalars['String']['output'];
  shippingContainerPayloadWeight: Scalars['Float']['output'];
  shippingContainerTareWeight: Scalars['Float']['output'];
  status?: Maybe<BookingContainerStatus>;
  stock?: Maybe<BookingContainerStock>;
  transportHistory?: Maybe<Array<BookingContainerTransportHistoryItem>>;
};

export type BookingContainerConnection = {
  __typename?: 'BookingContainerConnection';
  nodes: Array<Maybe<BookingContainer>>;
  pageInfo: PageInfo;
};

export type BookingContainerConnectionFilters = {
  bookingNumber?: InputMaybe<Scalars['String']['input']>;
  commodityCategory?: InputMaybe<Scalars['String']['input']>;
  dischargePortIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  originPortIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  shippingContainerNumber?: InputMaybe<Scalars['String']['input']>;
  steamshipLines?: InputMaybe<Array<SteamshipLine>>;
};

export type BookingContainerConnectionInput = {
  connection: ConnectionInput;
  filters?: InputMaybe<BookingContainerConnectionFilters>;
};

export type BookingContainerImage = {
  __typename?: 'BookingContainerImage';
  boundingBoxes?: Maybe<BoundingBoxes>;
  createdTime: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  type: BookingContainerImageType;
};

export type BookingContainerImageType =
  | 'CONTAINER_CLOSED_DOOR'
  | 'CONTAINER_OPEN_DOOR'
  | 'CONTAINER_SEAL'
  | 'LOADING_AREA';

export type BookingContainerStatus =
  | 'EMPTY'
  | 'INSPECTION_FAILED'
  | 'INSPECTION_PASSED'
  | 'LOADING_COMPLETE'
  | 'PORT_RETURNED'
  | 'SURRENDERED_EMPTY';

export type BookingContainerStock = {
  __typename?: 'BookingContainerStock';
  commodityInstances?: Maybe<Array<Maybe<CommodityInstance>>>;
  count: Scalars['Int']['output'];
  mass: Scalars['Float']['output'];
  maxPayloadWeight: Scalars['Float']['output'];
  scaleWeightDiffToMaxPayloadWeight: Scalars['Float']['output'];
  summary: Scalars['String']['output'];
  volume: Scalars['Float']['output'];
};

export type BookingContainerTransportHistoryItem = {
  __typename?: 'BookingContainerTransportHistoryItem';
  id: Scalars['ID']['output'];
  location: TransportLocation;
  time: Scalars['String']['output'];
};

export type BookingDocumentation = {
  __typename?: 'BookingDocumentation';
  bundleUrl?: Maybe<Scalars['String']['output']>;
  commercialInvoiceUrl?: Maybe<Scalars['String']['output']>;
  packingListUrl?: Maybe<Scalars['String']['output']>;
  shippingInstructionsUrl?: Maybe<Scalars['String']['output']>;
};

export type BookingStatus =
  | 'ACTIVE'
  | 'COMPLETE'
  | 'SOME_EMPTIES_SURRENDERED';

export type BoundingBoxes = {
  __typename?: 'BoundingBoxes';
  boundingBoxes: Array<Array<Point>>;
};

export type BoundingBoxesInput = {
  boundingBoxes: Array<Array<PointInput>>;
};

export type Buyer = {
  __typename?: 'Buyer';
  account?: Maybe<Account>;
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BuyerConnection = {
  __typename?: 'BuyerConnection';
  nodes: Array<Maybe<Buyer>>;
  pageInfo: PageInfo;
};

export type BuyerConnectionFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BuyerConnectionInput = {
  buyerConnectionFilters?: InputMaybe<BuyerConnectionFilters>;
  connection: ConnectionInput;
};

export type Chassis = {
  __typename?: 'Chassis';
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  owner: ChassisOwner;
  weight: Scalars['Float']['output'];
  weightInputUnit: WeightInputUnit;
};

export type ChassisConnection = {
  __typename?: 'ChassisConnection';
  nodes: Array<Maybe<Chassis>>;
  pageInfo: PageInfo;
};

export type ChassisConnectionFilters = {
  number?: InputMaybe<Scalars['String']['input']>;
};

export type ChassisConnectionInput = {
  chassisConnectionFilters?: InputMaybe<ChassisConnectionFilters>;
  connection: ConnectionInput;
};

export type ChassisOwner =
  | 'MILESTONE'
  | 'PORT_CHARLESTON';

export type Commodity = {
  __typename?: 'Commodity';
  commodityCategory: CommodityCategory;
  createdTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  details: CommodityDetails;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: CommodityStatus;
};

export type CommodityCategory =
  | 'SAWLOG';

export type CommodityConnection = {
  __typename?: 'CommodityConnection';
  nodes: Array<Maybe<Commodity>>;
  pageInfo: PageInfo;
};

export type CommodityConnectionFilters = {
  categories?: InputMaybe<Array<CommodityCategory>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<CommodityStatus>>;
};

export type CommodityConnectionInput = {
  commodityConnectionFilters?: InputMaybe<CommodityConnectionFilters>;
  connection: ConnectionInput;
};

export type CommodityDetails = SawlogCommodityDetails;

export type CommodityInstance = {
  __typename?: 'CommodityInstance';
  container?: Maybe<BookingContainer>;
  details: CommodityInstanceDetails;
  fingerprint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instanceType: CommodityInstanceType;
};

export type CommodityInstanceConnection = {
  __typename?: 'CommodityInstanceConnection';
  nodes: Array<Maybe<CommodityInstance>>;
  pageInfo: PageInfo;
};

export type CommodityInstanceDetails = SawlogInstanceDetails;

export type CommodityInstanceType =
  | 'SAWLOG';

export type CommodityPrice = {
  __typename?: 'CommodityPrice';
  /** The commodity that this price is for. */
  commodity?: Maybe<Commodity>;
  /** The destination that this price--combined with the given origin--applies to. */
  destination: TransportLocation;
  /** Which dimension of measurement this price applies to. */
  dimension: Dimension;
  /** This price's internal ID. */
  id: Scalars['ID']['output'];
  /** The incoterm that this price applies to. */
  incoterm: Incoterm;
  /** The origin that this price--combined with the given destination--applies to. */
  origin: TransportLocation;
  /** Whether the commodity price is still active, or has been updated to a new price. */
  status: CommodityPriceStatus;
  /** The price per base measurement unit in that dimension. */
  unitPrice: Money;
};

export type CommodityPriceConnection = {
  __typename?: 'CommodityPriceConnection';
  nodes: Array<Maybe<CommodityPrice>>;
  pageInfo: PageInfo;
};

export type CommodityPriceConnectionFilters = {
  destinationId?: InputMaybe<Array<Scalars['ID']['input']>>;
  dimension?: InputMaybe<Array<Dimension>>;
  incoterm?: InputMaybe<Array<Incoterm>>;
  originId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CommodityPriceConnectionInput = {
  commodityPriceFilters?: InputMaybe<CommodityPriceConnectionFilters>;
  connection: ConnectionInput;
};

export type CommodityPriceStatus =
  | 'ACTIVE'
  | 'EXPIRED';

export type CommodityStatus =
  | 'ACTIVE'
  | 'INACTIVE';

export type ConnectionInput = {
  pagination: PaginationInput;
  sort: Array<SortOrderInput>;
};

export type Contact = {
  __typename?: 'Contact';
  account?: Maybe<Account>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  bank?: Maybe<Bank>;
  businessCode?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mailingAddress?: Maybe<SimpleAddress>;
  name: Scalars['String']['output'];
  phone?: Maybe<PhoneNumber>;
  taxCode?: Maybe<Scalars['String']['output']>;
  tradeCode?: Maybe<Scalars['String']['output']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  nodes: Array<Maybe<Contact>>;
  pageInfo: PageInfo;
};

export type ContactConnectionFilters = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ContactConnectionInput = {
  connection: ConnectionInput;
  contactConnectionFilters?: InputMaybe<ContactConnectionFilters>;
};

export type Contractor = {
  __typename?: 'Contractor';
  account?: Maybe<Account>;
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ContractorConnection = {
  __typename?: 'ContractorConnection';
  nodes: Array<Maybe<Contractor>>;
  pageInfo: PageInfo;
};

export type ContractorConnectionFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ContractorConnectionInput = {
  connection: ConnectionInput;
  contractorConnectionFilters?: InputMaybe<ContractorConnectionFilters>;
};

export type Country = {
  __typename?: 'Country';
  alpha3Code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numericalCode: Scalars['String']['output'];
};

export type CreateAccountFields = {
  name: Scalars['String']['input'];
};

export type CreateAccountInput = {
  fields: CreateAccountFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateBankAccountFields = {
  accountId: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  bankId: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
};

export type CreateBankAccountInput = {
  fields: CreateBankAccountFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateBankFields = {
  name: Scalars['String']['input'];
};

export type CreateBankInput = {
  fields: CreateBankFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateBookingContainerFields = {
  bookingId: Scalars['String']['input'];
  chassisId?: InputMaybe<Scalars['ID']['input']>;
  seal?: InputMaybe<Scalars['String']['input']>;
  shippingContainerNumber: Scalars['String']['input'];
  shippingContainerPayloadWeight: Scalars['Float']['input'];
  shippingContainerTareWeight: Scalars['Float']['input'];
  weightInputUnit: WeightInputUnit;
};

export type CreateBookingContainerImageInput = {
  bookingContainerId: Scalars['ID']['input'];
  boundingBoxes?: InputMaybe<BoundingBoxesInput>;
  idempotencyKey: Scalars['String']['input'];
  imageId: Scalars['ID']['input'];
  imageUrl: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  type: BookingContainerImageType;
};

export type CreateBookingContainerInput = {
  fields: CreateBookingContainerFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateBookingContainerLoadFields = {
  destinationId: Scalars['ID']['input'];
  emptyWeight: Scalars['Float']['input'];
  grossWeight: Scalars['Float']['input'];
  originId: Scalars['ID']['input'];
  truckId: Scalars['ID']['input'];
  weightInputUnit: WeightInputUnit;
};

export type CreateBookingContainerLoadInput = {
  bookingContainerId: Scalars['ID']['input'];
  fields: CreateBookingContainerLoadFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateBookingFields = {
  aesCode?: InputMaybe<Scalars['String']['input']>;
  containerCount: Scalars['Int']['input'];
  destinationPortId: Scalars['String']['input'];
  documentCutoffDate: Scalars['String']['input'];
  earliestReturnDate: Scalars['String']['input'];
  emptyPickupLocationDescription: Scalars['String']['input'];
  estimatedArrivalDate: Scalars['String']['input'];
  estimatedDepartureDate: Scalars['String']['input'];
  externalBookingNumber: Scalars['String']['input'];
  fullRedeliveryLocationDescription: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
  originPortId: Scalars['String']['input'];
  portCutoffDate: Scalars['String']['input'];
  steamshipLine: SteamshipLine;
  vessel: Scalars['String']['input'];
  voyage: Scalars['String']['input'];
};

export type CreateBookingInput = {
  fields: CreateBookingFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateBuyerFields = {
  accountId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateBuyerInput = {
  fields: CreateBuyerFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateChassisFields = {
  number: Scalars['String']['input'];
  owner: ChassisOwner;
  weight: Scalars['Float']['input'];
  weightInputUnit: WeightInputUnit;
};

export type CreateChassisInput = {
  fields: CreateChassisFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateCommodityPriceFields = {
  commodityId: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
  destinationId: Scalars['ID']['input'];
  dimension: Dimension;
  incoterm: Incoterm;
  originId: Scalars['ID']['input'];
  /** The amount as a decimal string. */
  unitAmount: Scalars['String']['input'];
};

export type CreateCommodityPriceInput = {
  fields: CreateCommodityPriceFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateCommodityPriceResponse = {
  __typename?: 'CreateCommodityPriceResponse';
  price: CommodityPrice;
};

export type CreateContactFields = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['String']['input']>;
  businessCode?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneCountryCode?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  state: Scalars['String']['input'];
  taxCode?: InputMaybe<Scalars['String']['input']>;
  tradeCode?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CreateContactInput = {
  fields: CreateContactFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateContractorFields = {
  accountId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateContractorInput = {
  fields: CreateContractorFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateLandownerFields = {
  accountId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateLandownerInput = {
  fields: CreateLandownerFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateLetterOfCreditFields = {
  advisingBankReferenceNumber: Scalars['String']['input'];
  amount: Scalars['String']['input'];
  applicantBankId: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  date: Scalars['String']['input'];
  expirationDate: Scalars['String']['input'];
  externalNumber: Scalars['String']['input'];
  issuingBankId: Scalars['String']['input'];
  latestDateOfShipment: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  paymentTerms: LcPaymentTerms;
};

export type CreateLetterOfCreditInput = {
  fields: CreateLetterOfCreditFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateLoadFields = {
  bookingContainerId?: InputMaybe<Scalars['ID']['input']>;
  destinationId: Scalars['ID']['input'];
  emptyWeight: Scalars['Float']['input'];
  grossWeight: Scalars['Float']['input'];
  originId: Scalars['ID']['input'];
  ticketNumber: Scalars['String']['input'];
  truckId: Scalars['ID']['input'];
  weightInputUnit: WeightInputUnit;
};

export type CreateLoadInput = {
  fields: CreateLoadFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateOrderFields = {
  bookingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  buyerAccountId: Scalars['ID']['input'];
  commodityId: Scalars['ID']['input'];
  containerCount: Scalars['Int']['input'];
  destinationId: Scalars['ID']['input'];
  discountAmount?: InputMaybe<Scalars['String']['input']>;
  incoterm: Incoterm;
  paymentTerms: PaymentTerms;
  pricingDimension: Dimension;
};

export type CreateOrderInput = {
  fields: CreateOrderFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreatePortFields = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  shortLocationCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePortInput = {
  fields: CreatePortFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateProductInput = {
  approximateUnitAmounts: ProductUnitAmountsInput;
  description: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateProductResponse = {
  __typename?: 'CreateProductResponse';
  product?: Maybe<Product>;
};

export type CreateSawlogCommodityDetailsFields = {
  description: Scalars['String']['input'];
  lengthMeters: Scalars['Float']['input'];
  maximumDiameterCm: Scalars['Int']['input'];
  minimumDiameterCm: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  species: SawlogSpecies;
};

export type CreateSawlogCommodityDetailsInput = {
  fields: CreateSawlogCommodityDetailsFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateSawlogInstanceDetailsInput = {
  /** Meters */
  diameterOne: Scalars['Float']['input'];
  /** Meters */
  diameterTwo: Scalars['Float']['input'];
  idempotencyKey: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  length: Scalars['Float']['input'];
  longitude?: InputMaybe<Scalars['Float']['input']>;
  points: SawlogPointsInput;
  qrCodeId: Scalars['String']['input'];
  /** Meters reference */
  realWorldSizeReference: Scalars['Float']['input'];
  species: SawlogSpecies;
};

export type CreateSawlogInstancesInput = {
  bookingContainerId: Scalars['String']['input'];
  instances: Array<CreateSawlogInstanceDetailsInput>;
};

export type CreateTractFields = {
  landownerId: Scalars['ID']['input'];
  latitude: Scalars['Float']['input'];
  loggerId: Scalars['ID']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type CreateTractInput = {
  fields: CreateTractFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateTruckFields = {
  cabWeight: Scalars['Float']['input'];
  licensePlate: Scalars['String']['input'];
  truckerId: Scalars['ID']['input'];
  weightInputUnit: WeightInputUnit;
};

export type CreateTruckInput = {
  fields: CreateTruckFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateTruckerFields = {
  accountId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateTruckerInput = {
  fields: CreateTruckerFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateUserFields = {
  accountId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateUserInput = {
  fields: CreateUserFields;
  idempotencyKey: Scalars['String']['input'];
};

export type CreateYardFields = {
  contractorId: Scalars['ID']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
};

export type CreateYardInput = {
  fields: CreateYardFields;
  idempotencyKey: Scalars['String']['input'];
};

export type DashboardData = {
  __typename?: 'DashboardData';
  activeBookingCount: Scalars['Int']['output'];
  activeOrderCount: Scalars['Int']['output'];
  containerWeights: RetoolChartData;
  containersLoadedPerDay: RetoolChartData;
  containersReadyForPickup: Scalars['Int']['output'];
  containersReadyForReturn: Scalars['Int']['output'];
  containersReturned: Scalars['Int']['output'];
  containersShipped: Array<Scalars['Int']['output']>;
  containersSurrenderedEmpty: Scalars['Int']['output'];
  orderNumbers: Array<Scalars['String']['output']>;
  revenuePerOrder: Array<Scalars['String']['output']>;
  totalRevenue: Scalars['String']['output'];
};

export type DeleteBookingContainerImageInput = {
  bookingContainerImageId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
};

export type Dimension =
  | 'MASS'
  | 'VOLUME';

export type ExportContacts = {
  __typename?: 'ExportContacts';
  consignee?: Maybe<Contact>;
  firstNotify?: Maybe<Contact>;
  secondNotify?: Maybe<Contact>;
};

export type FormOption = {
  __typename?: 'FormOption';
  description: Scalars['String']['output'];
  detailDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type FormOptions = {
  __typename?: 'FormOptions';
  accountStatuses: Array<FormOption>;
  activeBookings: Array<FormOption>;
  bookingContainerStatuses: Array<FormOption>;
  bookingStatuses: Array<FormOption>;
  buyers: Array<FormOption>;
  commodityCategories: Array<FormOption>;
  commodityStatuses: Array<FormOption>;
  contractors: Array<FormOption>;
  documentTypes: Array<FormOption>;
  incoterms: Array<FormOption>;
  landowners: Array<FormOption>;
  loadStatuses: Array<FormOption>;
  orderStatuses: Array<FormOption>;
  ports: Array<FormOption>;
  pricingDimensions: Array<FormOption>;
  sawlogCameraOptions: Array<SawlogSpeciesLengthOption>;
  steamshipLines: Array<FormOption>;
  transportLocationStatuses: Array<FormOption>;
  transportLocations: Array<FormOption>;
  userStatuses: Array<FormOption>;
};

export type GenerateDocumentInput = {
  includeCoverPages: Scalars['Boolean']['input'];
  includeDetailedPackingLists: Scalars['Boolean']['input'];
  objectId: Scalars['String']['input'];
  type: GenerateDocumentType;
  withUrl: Scalars['Boolean']['input'];
};

export type GenerateDocumentResponse = {
  __typename?: 'GenerateDocumentResponse';
  buffer?: Maybe<Scalars['Byte']['output']>;
  filename: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type GenerateDocumentType =
  | 'BOOKING'
  | 'ORDER';

export type GenericCreateObjectResponse = {
  __typename?: 'GenericCreateObjectResponse';
  /** The 'dummy' field is here for backwards compatibility with GenericMutationResponse. */
  dummy?: Maybe<Scalars['Boolean']['output']>;
  /** The created object's ID. */
  id: Scalars['ID']['output'];
};

export type GenericMutationResponse = {
  __typename?: 'GenericMutationResponse';
  dummy?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * The Incoterms or International Commercial Terms are a series of pre-defined commercial terms
 * published by the International Chamber of Commerce (ICC) relating to international commercial
 * law. Incoterms define the responsibilities of exporters and importers in the arrangement of
 * shipments and the transfer of liability involved at various stages of the transaction.
 */
export type Incoterm =
  /** Abbreviated CIP. */
  | 'CARRIAGE_AND_INSURANCE_PAID_TO'
  /** Abbreviated CPT. */
  | 'CARRIAGE_PAID_TO'
  /** Abbreviated CFR. */
  | 'COST_AND_FREIGHT'
  /** Abbreviated CIF. */
  | 'COST_INSURANCE_AND_FREIGHT'
  /** Abbreviated DAP. */
  | 'DELIVERED_AT_PLACE'
  /** Abbreviated DPU. */
  | 'DELIVERED_AT_PLACE_UNLOADED'
  /** Abbreviated DDP. */
  | 'DELIVERED_DUTY_PAID'
  /** Abbreviated EXW. */
  | 'EX_WORKS'
  /** Abbreviated FAS. */
  | 'FREE_ALONGSIDE_SHIP'
  /** Abbreviated FCA. */
  | 'FREE_CARRIER'
  /** Abbreviated FOB. */
  | 'FREE_ON_BOARD';

export type LcPaymentTerms =
  | 'LC_150_DAYS'
  | 'LC_180_DAYS'
  | 'LC_AT_SIGHT';

export type Landowner = {
  __typename?: 'Landowner';
  account?: Maybe<Account>;
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LandownerConnection = {
  __typename?: 'LandownerConnection';
  nodes: Array<Maybe<Landowner>>;
  pageInfo: PageInfo;
};

export type LandownerConnectionFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LandownerConnectionInput = {
  connection: ConnectionInput;
  landownerConnectionFilters?: InputMaybe<LandownerConnectionFilters>;
};

export type LetterOfCredit = {
  __typename?: 'LetterOfCredit';
  advisingBankReferenceNumber: Scalars['String']['output'];
  amount: Money;
  applicantBank: Bank;
  date: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  externalNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  issuingBank: Bank;
  latestDateOfShipment: Scalars['String']['output'];
  order: Order;
  paymentTerms: LcPaymentTerms;
};

export type LetterOfCreditConnection = {
  __typename?: 'LetterOfCreditConnection';
  nodes: Array<Maybe<LetterOfCredit>>;
  pageInfo: PageInfo;
};

export type LetterOfCreditConnectionFilters = {
  externalNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type LetterOfCreditConnectionInput = {
  connection: ConnectionInput;
  letterOfCreditConnectionFilters?: InputMaybe<LetterOfCreditConnectionFilters>;
};

export type Load = {
  __typename?: 'Load';
  container?: Maybe<BookingContainer>;
  createdTime: Scalars['String']['output'];
  destination?: Maybe<TransportLocation>;
  id: Scalars['ID']['output'];
  origin?: Maybe<TransportLocation>;
  status: LoadStatus;
  ticketNumber: Scalars['String']['output'];
  truck?: Maybe<Truck>;
  trucker?: Maybe<Trucker>;
  weights?: Maybe<LoadWeights>;
};

export type LoadConnection = {
  __typename?: 'LoadConnection';
  nodes: Array<Maybe<Load>>;
  pageInfo: PageInfo;
};

export type LoadConnectionFilters = {
  bookingContainerId?: InputMaybe<Scalars['String']['input']>;
  createdDate?: InputMaybe<Scalars['String']['input']>;
  destinationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  landownerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  loggerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  originIds?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<LoadStatus>>;
  ticketNumber?: InputMaybe<Scalars['String']['input']>;
};

export type LoadConnectionInput = {
  connection: ConnectionInput;
  loadConnectionFilters?: InputMaybe<LoadConnectionFilters>;
};

export type LoadStatus =
  | 'ACTIVE'
  | 'CANCELLED'
  | 'COMPLETE';

export type LoadTransportLocation = {
  __typename?: 'LoadTransportLocation';
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  transportLocationId: Scalars['ID']['output'];
  transportLocationType: TransportLocationType;
};

export type LoadWeights = {
  __typename?: 'LoadWeights';
  createdTime: Scalars['String']['output'];
  emptyWeight: Scalars['Float']['output'];
  grossWeight: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  netWeight: Scalars['Float']['output'];
  weightInputUnit: WeightInputUnit;
};

export type Money = {
  __typename?: 'Money';
  /**
   * The amount as a base-10 string that should should be parsed via libraries such as
   * various languages' 'BigDecimal' libraries.
   */
  amount: Scalars['String']['output'];
  /** The currency's ISO 4217 code. */
  currency: Scalars['String']['output'];
};

export type MoneyInput = {
  amount: Scalars['String']['input'];
  currency: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCommodityInstances: GenericMutationResponse;
  createAccount: GenericCreateObjectResponse;
  createBank: GenericCreateObjectResponse;
  createBankAccount: GenericCreateObjectResponse;
  createBooking: GenericCreateObjectResponse;
  createBookingContainer: GenericCreateObjectResponse;
  createBookingContainerImage: GenericCreateObjectResponse;
  createBookingContainerLoad: GenericCreateObjectResponse;
  createBuyer: GenericCreateObjectResponse;
  createChassis: GenericCreateObjectResponse;
  createCommodityPrice: CreateCommodityPriceResponse;
  createContact: GenericCreateObjectResponse;
  createContractor: GenericCreateObjectResponse;
  createLandowner: GenericCreateObjectResponse;
  createLetterOfCredit: GenericCreateObjectResponse;
  createLoad: GenericCreateObjectResponse;
  createOrder: GenericCreateObjectResponse;
  createPort: GenericCreateObjectResponse;
  createProduct: CreateProductResponse;
  createSawlogCommodity: GenericCreateObjectResponse;
  createSawlogInstances: GenericMutationResponse;
  createTract: GenericCreateObjectResponse;
  createTruck: GenericCreateObjectResponse;
  createTrucker: GenericCreateObjectResponse;
  createUser: GenericCreateObjectResponse;
  createYard: GenericCreateObjectResponse;
  deleteBookingContainerImage: GenericMutationResponse;
  removeCommodityInstances: GenericMutationResponse;
  requestUserAccountDeletion: User;
  updateAccount: GenericMutationResponse;
  updateBank: GenericMutationResponse;
  updateBankAccount: GenericMutationResponse;
  updateBooking: GenericMutationResponse;
  updateBookingContainer: GenericMutationResponse;
  updateBuyer: GenericMutationResponse;
  updateChassis: GenericMutationResponse;
  updateContact: GenericMutationResponse;
  updateContractor: GenericMutationResponse;
  updateLandowner: GenericMutationResponse;
  updateLetterOfCredit: GenericMutationResponse;
  updateLoad: GenericMutationResponse;
  updateOrder: GenericMutationResponse;
  updatePort: GenericMutationResponse;
  updateSawlogCommodityDetails: GenericMutationResponse;
  updateTract: GenericMutationResponse;
  updateTruck: GenericMutationResponse;
  updateTrucker: GenericMutationResponse;
  updateUser: GenericMutationResponse;
  updateYard: GenericMutationResponse;
};


export type MutationAddCommodityInstancesArgs = {
  input: AddCommodityInstancesInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateBankArgs = {
  input: CreateBankInput;
};


export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};


export type MutationCreateBookingArgs = {
  input: CreateBookingInput;
};


export type MutationCreateBookingContainerArgs = {
  input: CreateBookingContainerInput;
};


export type MutationCreateBookingContainerImageArgs = {
  input: CreateBookingContainerImageInput;
};


export type MutationCreateBookingContainerLoadArgs = {
  input: CreateBookingContainerLoadInput;
};


export type MutationCreateBuyerArgs = {
  input: CreateBuyerInput;
};


export type MutationCreateChassisArgs = {
  input: CreateChassisInput;
};


export type MutationCreateCommodityPriceArgs = {
  input: CreateCommodityPriceInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateContractorArgs = {
  input: CreateContractorInput;
};


export type MutationCreateLandownerArgs = {
  input: CreateLandownerInput;
};


export type MutationCreateLetterOfCreditArgs = {
  input: CreateLetterOfCreditInput;
};


export type MutationCreateLoadArgs = {
  input: CreateLoadInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreatePortArgs = {
  input: CreatePortInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateSawlogCommodityArgs = {
  input: CreateSawlogCommodityDetailsInput;
};


export type MutationCreateSawlogInstancesArgs = {
  input: CreateSawlogInstancesInput;
};


export type MutationCreateTractArgs = {
  input: CreateTractInput;
};


export type MutationCreateTruckArgs = {
  input: CreateTruckInput;
};


export type MutationCreateTruckerArgs = {
  input: CreateTruckerInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateYardArgs = {
  input: CreateYardInput;
};


export type MutationDeleteBookingContainerImageArgs = {
  input: DeleteBookingContainerImageInput;
};


export type MutationRemoveCommodityInstancesArgs = {
  input: RemoveCommodityInstancesInput;
};


export type MutationRequestUserAccountDeletionArgs = {
  input: RequestUserAccountDeletionInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateBankArgs = {
  input: UpdateBankInput;
};


export type MutationUpdateBankAccountArgs = {
  input: UpdateBankAccountInput;
};


export type MutationUpdateBookingArgs = {
  input: UpdateBookingInput;
};


export type MutationUpdateBookingContainerArgs = {
  input: UpdateBookingContainerInput;
};


export type MutationUpdateBuyerArgs = {
  input: UpdateBuyerInput;
};


export type MutationUpdateChassisArgs = {
  input: UpdateChassisInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateContractorArgs = {
  input: UpdateContractorInput;
};


export type MutationUpdateLandownerArgs = {
  input: UpdateLandownerInput;
};


export type MutationUpdateLetterOfCreditArgs = {
  input: UpdateLetterOfCreditInput;
};


export type MutationUpdateLoadArgs = {
  input: UpdateLoadInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdatePortArgs = {
  input: UpdatePortInput;
};


export type MutationUpdateSawlogCommodityDetailsArgs = {
  input: UpdateSawlogCommodityDetailsInput;
};


export type MutationUpdateTractArgs = {
  input: UpdateTractInput;
};


export type MutationUpdateTruckArgs = {
  input: UpdateTruckInput;
};


export type MutationUpdateTruckerArgs = {
  input: UpdateTruckerInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateYardArgs = {
  input: UpdateYardInput;
};

export type OperatingRegionType =
  | 'CHARLESTON'
  | 'CHENNAI'
  | 'DANANG'
  | 'DURBAN'
  | 'ENORE'
  | 'HAIPHONG'
  | 'HO_CHI_MINH'
  | 'ITAJAI'
  | 'JEBEL_ALI'
  | 'KARACHI'
  | 'KATTUPALI'
  | 'KOLKATA'
  | 'MUNDRA'
  | 'NAVEGANTES'
  | 'PORT_QASIM'
  | 'QUI_NHON'
  | 'SAVANNAH'
  | 'TUTICORN';

export type Order = {
  __typename?: 'Order';
  /**
   * The total price of this order, established once we measured the actual amount of product
   * that would would deliver.
   */
  actualTotal?: Maybe<Money>;
  /** Bookings */
  bookings?: Maybe<Array<Booking>>;
  /** The account purchasing the order. */
  buyerAccount: Account;
  /** The commodities that are being purchased via this order and their pricing details. */
  commodityPrices: Array<CommodityPrice>;
  /** How many containers have been loaded and returned to port. */
  completeContainerCount: Scalars['Int']['output'];
  /** How many containers of this mix of commodities are being purchased. */
  containerCount: Scalars['Int']['output'];
  /** The location where this order is to be delivered. */
  destination: TransportLocation;
  /** The discount from the list price applied to this order. */
  discountAmount?: Maybe<Money>;
  /** Contacts who may require involvement in fulfilling the order. */
  exportContacts?: Maybe<ExportContacts>;
  /** The "order number" we display to end users. */
  externalNumber: Scalars['String']['output'];
  /** The internal ID for the ID. */
  id: Scalars['ID']['output'];
  /** This order's incoterms. (See Incoterm documentation.) */
  incoterm: Incoterm;
  /** Payment Terms */
  paymentTerms: PaymentTerms;
  /** The official time when the order was placed as far as accounting is concerned. */
  postedAt: Scalars['String']['output'];
  /**
   * The quoted total price when this order was placed, based on the unit price and the
   * estimated amount of product that the order would contain.
   */
  quotedTotal: Money;
  /** The order's current status. */
  status: OrderStatus;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  nodes: Array<Maybe<Order>>;
  pageInfo: PageInfo;
};

export type OrderConnectionFilters = {
  accountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  externalNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incoterms?: InputMaybe<Array<Incoterm>>;
  statuses?: InputMaybe<Array<OrderStatus>>;
};

export type OrderConnectionInput = {
  connection: ConnectionInput;
  orderConnectionFilters?: InputMaybe<OrderConnectionFilters>;
};

export type OrderStatus =
  | 'ACTIVE'
  | 'CANCELLED'
  | 'COMPLETE';

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
};

export type PaymentTerms =
  | 'DOCUMENTS_AGAINST_PAYMENT'
  | 'LC_150_DAYS'
  | 'LC_180_DAYS'
  | 'LC_AT_SIGHT'
  | 'NET_10_DAYS'
  | 'NET_30_DAYS'
  | 'NET_60_DAYS'
  | 'NET_90_DAYS';

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  /** The country code, not including the '+'. */
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  /** The phone number, not including the country code or any separators between digits. */
  phoneNumber: Scalars['String']['output'];
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type PointInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type Product = {
  __typename?: 'Product';
  /** The user-friendly description of the product. */
  description: Scalars['String']['output'];
  /** The product's internal ID. */
  id: Scalars['ID']['output'];
  /** The user-friendly name of the product. */
  name: Scalars['String']['output'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  nodes: Array<Maybe<Product>>;
  pageInfo: PageInfo;
};

/** Several amounts expressed in their SI units. */
export type ProductUnitAmounts = {
  __typename?: 'ProductUnitAmounts';
  mass?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type ProductUnitAmountsInput = {
  mass?: InputMaybe<Scalars['Float']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
};

export type Query = {
  __typename?: 'Query';
  accountContactCreateFormSchema?: Maybe<RetoolFormSchema>;
  accountContactUpdateFormSchema: RetoolFormSchema;
  accountCreateFormSchema?: Maybe<RetoolFormSchema>;
  accountUpdateFormSchema: RetoolFormSchema;
  accounts: AccountConnection;
  bankAccountCreateFormSchema?: Maybe<RetoolFormSchema>;
  bankAccountUpdateFormSchema: RetoolFormSchema;
  bankAccounts: BankAccountConnection;
  bankContactCreateFormSchema?: Maybe<RetoolFormSchema>;
  bankContactUpdateFormSchema: RetoolFormSchema;
  bankCreateFormSchema?: Maybe<RetoolFormSchema>;
  bankUpdateFormSchema: RetoolFormSchema;
  banks: BankConnection;
  bookingContainer: BookingContainer;
  bookingContainerCreateFormSchema?: Maybe<RetoolFormSchema>;
  bookingContainerLoadCreateFormSchema: RetoolFormSchema;
  bookingContainerUpdateFormSchema: RetoolFormSchema;
  bookingContainers: BookingContainerConnection;
  bookingCreateFormSchema?: Maybe<RetoolFormSchema>;
  bookingUpdateFormSchema: RetoolFormSchema;
  bookings: BookingConnection;
  buyerCreateFormSchema?: Maybe<RetoolFormSchema>;
  buyerUpdateFormSchema: RetoolFormSchema;
  buyers: BuyerConnection;
  chassisConnection: ChassisConnection;
  chassisCreateFormSchema: RetoolFormSchema;
  chassisUpdateFormSchema: RetoolFormSchema;
  commodityPriceCreateFormSchema: RetoolFormSchema;
  commodityPrices: CommodityPriceConnection;
  contacts: ContactConnection;
  contractorCreateFormSchema?: Maybe<RetoolFormSchema>;
  contractorUpdateFormSchema: RetoolFormSchema;
  contractors: ContractorConnection;
  currentUser: User;
  dashboardData: DashboardData;
  formOptions: FormOptions;
  generateDocument: GenerateDocumentResponse;
  landownerCreateFormSchema?: Maybe<RetoolFormSchema>;
  landownerUpdateFormSchema: RetoolFormSchema;
  landowners: LandownerConnection;
  letterOfCreditCreateFormSchema: RetoolFormSchema;
  letterOfCreditUpdateFormSchema: RetoolFormSchema;
  lettersOfCredit: LetterOfCreditConnection;
  loadCreateFormSchema?: Maybe<RetoolFormSchema>;
  loadUpdateFormSchema: RetoolFormSchema;
  loads: LoadConnection;
  orderCreateFormSchema: RetoolFormSchema;
  orderUpdateFormSchema: RetoolFormSchema;
  orders: OrderConnection;
  portCreateFormSchema: RetoolFormSchema;
  portUpdateFormSchema: RetoolFormSchema;
  ports: TransportLocationConnection;
  sawlogCommodities: CommodityConnection;
  sawlogCommodityCreateFormSchema: RetoolFormSchema;
  sawlogCommodityUpdateFormSchema: RetoolFormSchema;
  sawlogsInstances: CommodityInstanceConnection;
  tractCreateFormSchema: RetoolFormSchema;
  tractUpdateFormSchema: RetoolFormSchema;
  tracts: TransportLocationConnection;
  truckCreateFormSchema: RetoolFormSchema;
  truckUpdateFormSchema: RetoolFormSchema;
  truckerCreateFormSchema?: Maybe<RetoolFormSchema>;
  truckerUpdateFormSchema: RetoolFormSchema;
  truckers: TruckerConnection;
  trucks: TruckConnection;
  userCreateFormSchema?: Maybe<RetoolFormSchema>;
  userUpdateFormSchema: RetoolFormSchema;
  users: UserConnection;
  yardCreateFormSchema: RetoolFormSchema;
  yardUpdateFormSchema: RetoolFormSchema;
  yards: TransportLocationConnection;
};


export type QueryAccountContactUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryAccountUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryAccountsArgs = {
  input: AccountConnectionInput;
};


export type QueryBankAccountUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryBankAccountsArgs = {
  input: BankAccountConnectionInput;
};


export type QueryBankContactUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryBankUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryBanksArgs = {
  input: BankConnectionInput;
};


export type QueryBookingContainerArgs = {
  id: Scalars['String']['input'];
};


export type QueryBookingContainerLoadCreateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryBookingContainerUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryBookingContainersArgs = {
  input: BookingContainerConnectionInput;
};


export type QueryBookingUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryBookingsArgs = {
  input: BookingConnectionInput;
};


export type QueryBuyerUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryBuyersArgs = {
  input: BuyerConnectionInput;
};


export type QueryChassisConnectionArgs = {
  input: ChassisConnectionInput;
};


export type QueryChassisUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryCommodityPricesArgs = {
  input: CommodityPriceConnectionInput;
};


export type QueryContactsArgs = {
  input: ContactConnectionInput;
};


export type QueryContractorUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryContractorsArgs = {
  input: ContractorConnectionInput;
};


export type QueryGenerateDocumentArgs = {
  input: GenerateDocumentInput;
};


export type QueryLandownerUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryLandownersArgs = {
  input: LandownerConnectionInput;
};


export type QueryLetterOfCreditUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryLettersOfCreditArgs = {
  input: LetterOfCreditConnectionInput;
};


export type QueryLoadUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryLoadsArgs = {
  input: LoadConnectionInput;
};


export type QueryOrderUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryOrdersArgs = {
  input: OrderConnectionInput;
};


export type QueryPortUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryPortsArgs = {
  input: TransportLocationConnectionInput;
};


export type QuerySawlogCommoditiesArgs = {
  input: CommodityConnectionInput;
};


export type QuerySawlogCommodityUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QuerySawlogsInstancesArgs = {
  input: SawlogInstanceConnectionInput;
};


export type QueryTractUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryTractsArgs = {
  input: TransportLocationConnectionInput;
};


export type QueryTruckUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryTruckerUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryTruckersArgs = {
  input: TruckerConnectionInput;
};


export type QueryTrucksArgs = {
  input: TruckConnectionInput;
};


export type QueryUserUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryUsersArgs = {
  input: UserConnectionInput;
};


export type QueryYardUpdateFormSchemaArgs = {
  input?: InputMaybe<RetoolFormSchemaUpdateInput>;
};


export type QueryYardsArgs = {
  input: TransportLocationConnectionInput;
};

export type QueryOrdersInput = {
  pagination: PaginationInput;
};

export type RemoveCommodityInstancesInput = {
  fingerprints: Array<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
};

export type RequestUserAccountDeletionInput = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type RetoolChartData = {
  __typename?: 'RetoolChartData';
  xData: Array<Scalars['String']['output']>;
  yData: Array<Scalars['String']['output']>;
};

export type RetoolFilterInput = {
  columnId: Scalars['String']['input'];
  operator: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type RetoolFiltersInput = {
  filters: Array<RetoolFilterInput>;
  operator?: InputMaybe<Scalars['String']['input']>;
};

export type RetoolFormSchema = {
  __typename?: 'RetoolFormSchema';
  defaultData: Scalars['String']['output'];
  jsonSchema: Scalars['String']['output'];
  uiSchema: Scalars['String']['output'];
};

export type RetoolFormSchemaUpdateInput = {
  objectId: Scalars['String']['input'];
};

export type SawlogCommodityDetails = {
  __typename?: 'SawlogCommodityDetails';
  id: Scalars['String']['output'];
  length: Scalars['Float']['output'];
  maximumDiameterCm: Scalars['Int']['output'];
  minimumDiameterCm: Scalars['Int']['output'];
  species: SawlogSpecies;
};

export type SawlogInstanceConnectionFilters = {
  bookingIds?: InputMaybe<Array<Scalars['String']['input']>>;
  speciesLength?: InputMaybe<Array<SawlogSpeciesLengthInput>>;
};

export type SawlogInstanceConnectionInput = {
  connection: ConnectionInput;
  sawlogFilters?: InputMaybe<SawlogInstanceConnectionFilters>;
};

export type SawlogInstanceDetails = {
  __typename?: 'SawlogInstanceDetails';
  /** @deprecated Use the equivalent field in the base CommodityInstanceDetails type. */
  container?: Maybe<BookingContainer>;
  createdTime: Scalars['String']['output'];
  diameterOneCm: Scalars['Float']['output'];
  diameterTwoCm: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  jas: Scalars['Float']['output'];
  jasRoundedDiameterCm: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  length: Scalars['Float']['output'];
  longitude?: Maybe<Scalars['Float']['output']>;
  points: SawlogPoints;
  sevenCharacterIdSuffix: Scalars['String']['output'];
  species: SawlogSpecies;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type SawlogPoints = {
  __typename?: 'SawlogPoints';
  diameterOnePoints: Array<Point>;
  diameterTwoPoints: Array<Point>;
  perimeterPoints?: Maybe<Array<Maybe<Point>>>;
  qrCodePoints: Array<Point>;
};

export type SawlogPointsInput = {
  diameterOnePoints: Array<PointInput>;
  diameterTwoPoints: Array<PointInput>;
  perimeterPoints: Array<PointInput>;
  qrCodePoints: Array<PointInput>;
};

export type SawlogSpecies =
  | 'DOUGLAS_FIR'
  | 'EUCALYPTUS'
  | 'GRANDIS_EUCALYPTUS'
  | 'HEMLOCK'
  | 'PINE'
  | 'PINE_SOUTHERN_YELLOW'
  | 'PINE_TAEDA'
  | 'POPLAR'
  | 'RED_OAK'
  | 'WHITE_OAK';

export type SawlogSpeciesLengthInput = {
  length: Scalars['Float']['input'];
  species: SawlogSpecies;
};

export type SawlogSpeciesLengthOption = {
  __typename?: 'SawlogSpeciesLengthOption';
  displayString: Scalars['String']['output'];
  length: Scalars['Float']['output'];
  species: SawlogSpecies;
};

/**
 * A simple representation of a street address. This should be replaced with
 * something more strongly typed (not everything should just be a string)
 * and more suitable for international addresses in the future.
 */
export type SimpleAddress = {
  __typename?: 'SimpleAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type SimpleAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

/**
 * Values of this enum are supposed to be "ASC" | "DESC", but exception is made because
 * Retool uses lowercase strings, and we decided not to refactor the usage in the Retool.
 */
export type SortDirection =
  | 'asc'
  | 'desc';

export type SortOrderInput = {
  direction: SortDirection;
  fieldName: Scalars['String']['input'];
};

export type SteamshipLine =
  | 'CMA_CGM'
  | 'COSU'
  | 'EMCU'
  | 'HLCU'
  | 'HMM'
  | 'MAEU'
  | 'MSC'
  | 'ONE'
  | 'OOCL'
  | 'YMLU'
  | 'ZIMU';

export type Subscription = {
  __typename?: 'Subscription';
  bookingContainer?: Maybe<BookingContainer>;
};


export type SubscriptionBookingContainerArgs = {
  id: Scalars['String']['input'];
};

/**
 * A location used in trade and transport, such as a seaport, a road terminal,
 * or an airport.
 */
export type TransportLocation = {
  __typename?: 'TransportLocation';
  /** 2-letter country code (IN) */
  countryCode?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  /** 5-letter full LOCODE (INMUN) */
  locode?: Maybe<Scalars['String']['output']>;
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  partners?: Maybe<Array<Maybe<TransportLocationPartner>>>;
  /** 3-letter location code (MUN) */
  shortLocationCode?: Maybe<Scalars['String']['output']>;
  status: TransportLocationStatus;
  type: TransportLocationType;
};

export type TransportLocationConnection = {
  __typename?: 'TransportLocationConnection';
  nodes: Array<TransportLocation>;
  pageInfo: PageInfo;
};

export type TransportLocationConnectionFilters = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<TransportLocationStatus>>;
};

export type TransportLocationConnectionInput = {
  connection: ConnectionInput;
  transportLocationConnectionFilters?: InputMaybe<TransportLocationConnectionFilters>;
};

export type TransportLocationPartner = {
  __typename?: 'TransportLocationPartner';
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  partnerId: Scalars['ID']['output'];
  partnerType: TransportLocationPartnerType;
};

export type TransportLocationPartnerType =
  | 'CONTRACTOR'
  | 'LANDOWNER';

export type TransportLocationStatus =
  | 'ACTIVE'
  | 'INACTIVE';

export type TransportLocationType =
  | 'OTHER'
  | 'PORT'
  | 'TRACT'
  | 'WAREHOUSE'
  | 'YARD';

export type Truck = {
  __typename?: 'Truck';
  cabWeight: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  trucker?: Maybe<Trucker>;
  weightInputUnit: WeightInputUnit;
};

export type TruckConnection = {
  __typename?: 'TruckConnection';
  nodes: Array<Maybe<Truck>>;
  pageInfo: PageInfo;
};

export type TruckConnectionFilters = {
  licensePlate?: InputMaybe<Scalars['String']['input']>;
};

export type TruckConnectionInput = {
  connection: ConnectionInput;
  truckConnectionFilters?: InputMaybe<TruckConnectionFilters>;
};

export type Trucker = {
  __typename?: 'Trucker';
  account?: Maybe<Account>;
  createdTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TruckerConnection = {
  __typename?: 'TruckerConnection';
  nodes: Array<Maybe<Trucker>>;
  pageInfo: PageInfo;
};

export type TruckerConnectionFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TruckerConnectionInput = {
  connection: ConnectionInput;
  truckerConnectionFilters?: InputMaybe<TruckerConnectionFilters>;
};

/**
 * The backend stores everything in SI units but converts the price
 * per unit and the unit name to populate this type.
 */
export type UnitPrice = {
  __typename?: 'UnitPrice';
  /** A reference to the DB record that corresponds to this price. */
  id: Scalars['ID']['output'];
  /** The localized unit's user-friendly name. */
  unitName?: Maybe<Scalars['String']['output']>;
  /** The localized price per localized unit. */
  value?: Maybe<Money>;
};

export type UpdateAccountFields = {
  name?: InputMaybe<Scalars['String']['input']>;
  status: AccountStatus;
};

export type UpdateAccountInput = {
  accountId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateAccountFields;
};

export type UpdateBankAccountFields = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  doArchive?: InputMaybe<Scalars['Boolean']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBankAccountInput = {
  bankAccountId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateBankAccountFields;
};

export type UpdateBankFields = {
  doArchive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBankInput = {
  bankId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateBankFields;
};

export type UpdateBookingContainerFields = {
  bookingId?: InputMaybe<Scalars['String']['input']>;
  chassisId?: InputMaybe<Scalars['ID']['input']>;
  seal?: InputMaybe<Scalars['String']['input']>;
  shippingContainerNumber?: InputMaybe<Scalars['String']['input']>;
  shippingContainerPayloadWeight?: InputMaybe<Scalars['Float']['input']>;
  shippingContainerTareWeight?: InputMaybe<Scalars['Float']['input']>;
  weightInputUnit?: InputMaybe<WeightInputUnit>;
};

export type UpdateBookingContainerInput = {
  bookingContainerId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateBookingContainerFields;
};

export type UpdateBookingFields = {
  aesCode?: InputMaybe<Scalars['String']['input']>;
  billOfLadingNumber?: InputMaybe<Scalars['String']['input']>;
  containerCount?: InputMaybe<Scalars['Int']['input']>;
  destinationPortId?: InputMaybe<Scalars['String']['input']>;
  documentCutoffDate?: InputMaybe<Scalars['String']['input']>;
  earliestReturnDate?: InputMaybe<Scalars['String']['input']>;
  emptyPickupLocationDescription?: InputMaybe<Scalars['String']['input']>;
  estimatedArrivalDate?: InputMaybe<Scalars['String']['input']>;
  estimatedDepartureDate?: InputMaybe<Scalars['String']['input']>;
  externalBookingNumber?: InputMaybe<Scalars['String']['input']>;
  fullRedeliveryLocationDescription?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  originPortId?: InputMaybe<Scalars['String']['input']>;
  portCutoffDate?: InputMaybe<Scalars['String']['input']>;
  steamshipLine?: InputMaybe<Scalars['String']['input']>;
  vessel?: InputMaybe<Scalars['String']['input']>;
  voyage?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBookingInput = {
  bookingId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateBookingFields;
};

export type UpdateBuyerFields = {
  disableAccount?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBuyerInput = {
  buyerId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateBuyerFields;
};

export type UpdateChassisFields = {
  number?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<ChassisOwner>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightInputUnit?: InputMaybe<WeightInputUnit>;
};

export type UpdateChassisInput = {
  chassisId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateChassisFields;
};

export type UpdateContactFields = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  businessCode?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneCountryCode?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  tradeCode?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  contactId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update?: InputMaybe<UpdateContactFields>;
};

export type UpdateContractorFields = {
  disableAccount?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractorInput = {
  contractorId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateContractorFields;
};

export type UpdateLandownerFields = {
  disableAccount?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLandownerInput = {
  idempotencyKey: Scalars['String']['input'];
  landownerId: Scalars['String']['input'];
  update: UpdateLandownerFields;
};

export type UpdateLetterOfCreditFields = {
  advisingBankReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['String']['input']>;
  applicantBankId?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  externalNumber?: InputMaybe<Scalars['String']['input']>;
  issuingBankId?: InputMaybe<Scalars['String']['input']>;
  latestDateOfShipment?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  paymentTerms?: InputMaybe<LcPaymentTerms>;
};

export type UpdateLetterOfCreditInput = {
  idempotencyKey: Scalars['String']['input'];
  letterOfCreditId: Scalars['String']['input'];
  update: UpdateLetterOfCreditFields;
};

export type UpdateLoadFields = {
  bookingContainerId?: InputMaybe<Scalars['String']['input']>;
  destinationId?: InputMaybe<Scalars['String']['input']>;
  emptyWeight: Scalars['Float']['input'];
  grossWeight: Scalars['Float']['input'];
  originId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LoadStatus>;
  ticketNumber?: InputMaybe<Scalars['String']['input']>;
  truckId?: InputMaybe<Scalars['String']['input']>;
  weightInputUnit: WeightInputUnit;
};

export type UpdateLoadInput = {
  idempotencyKey: Scalars['String']['input'];
  loadId: Scalars['String']['input'];
  update: UpdateLoadFields;
};

export type UpdateOrderFields = {
  bookingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  buyerAccountId?: InputMaybe<Scalars['ID']['input']>;
  consigneeId?: InputMaybe<Scalars['ID']['input']>;
  containerCount?: InputMaybe<Scalars['Int']['input']>;
  discountAmount?: InputMaybe<Scalars['String']['input']>;
  firstNotifyId?: InputMaybe<Scalars['ID']['input']>;
  paymentTerms?: InputMaybe<PaymentTerms>;
  secondNotifyId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrderInput = {
  idempotencyKey: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  update: UpdateOrderFields;
};

export type UpdatePortFields = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortLocationCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransportLocationStatus>;
};

export type UpdatePortInput = {
  idempotencyKey: Scalars['String']['input'];
  portId: Scalars['String']['input'];
  update: UpdatePortFields;
};

export type UpdateSawlogCommodityDetailsInput = {
  commodityId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  update: UpdateSawlogCommodityFields;
};

export type UpdateSawlogCommodityFields = {
  description?: InputMaybe<Scalars['String']['input']>;
  lengthMeters?: InputMaybe<Scalars['Float']['input']>;
  maximumDiameterCm?: InputMaybe<Scalars['Int']['input']>;
  minimumDiameterCm?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  species?: InputMaybe<SawlogSpecies>;
  status?: InputMaybe<CommodityStatus>;
};

export type UpdateTractFields = {
  landownerId?: InputMaybe<Scalars['ID']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  loggerId?: InputMaybe<Scalars['ID']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransportLocationStatus>;
};

export type UpdateTractInput = {
  idempotencyKey: Scalars['String']['input'];
  tractId: Scalars['String']['input'];
  update: UpdateTractFields;
};

export type UpdateTruckFields = {
  cabWeight?: InputMaybe<Scalars['Float']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  truckerId?: InputMaybe<Scalars['String']['input']>;
  weightInputUnit?: InputMaybe<WeightInputUnit>;
};

export type UpdateTruckInput = {
  idempotencyKey: Scalars['String']['input'];
  truckId: Scalars['String']['input'];
  update: UpdateTruckFields;
};

export type UpdateTruckerFields = {
  disableAccount?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTruckerInput = {
  idempotencyKey: Scalars['String']['input'];
  truckerId: Scalars['String']['input'];
  update: UpdateTruckerFields;
};

export type UpdateUserAccountFields = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateUserAccountInput = {
  idempotencyKey: Scalars['String']['input'];
  update: UpdateUserAccountFields;
  userId: Scalars['String']['input'];
};

export type UpdateUserFields = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status: UserStatus;
};

export type UpdateUserInput = {
  idempotencyKey: Scalars['String']['input'];
  update: UpdateUserFields;
  userId: Scalars['String']['input'];
};

export type UpdateYardFields = {
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransportLocationStatus>;
};

export type UpdateYardInput = {
  idempotencyKey: Scalars['String']['input'];
  update: UpdateYardFields;
  yardId: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  account?: Maybe<Account>;
  accountDeletionRequested?: Maybe<Scalars['Boolean']['output']>;
  createdTime: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  status: UserStatus;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<Maybe<User>>;
  pageInfo: PageInfo;
};

export type UserConnectionFilters = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<UserStatus>>;
};

export type UserConnectionInput = {
  connection: ConnectionInput;
  userConnectionFilters?: InputMaybe<UserConnectionFilters>;
};

export type UserStatus =
  | 'ACTIVE'
  | 'ARCHIVED';

export type WeightInputUnit =
  | 'KILOGRAM'
  | 'METRIC_TON'
  | 'POUND';

/** A date in a particular location. */
export type ZonedDate = {
  __typename?: 'ZonedDate';
  day: Scalars['Int']['output'];
  humanReadableDateString: Scalars['String']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
  /**
   * A "tz" time zone, such as "America/Los_Angeles". We define the location
   * this way, as opposed to using a UTC offset, because the UTC offset may
   * change in the middle of a date at a given location--as it does when
   * daylight saving time ends or begins.
   */
  zoneId?: Maybe<Scalars['String']['output']>;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, email: string, name: string, phone?: string | undefined, createdTime: string, status: UserStatus, accountDeletionRequested?: boolean | undefined, account?: { __typename?: 'Account', id: string, name: string, status: AccountStatus, createdTime: string } | undefined } };

export type GqlUserFragment = { __typename?: 'User', id: string, email: string, name: string, phone?: string | undefined, createdTime: string, status: UserStatus, accountDeletionRequested?: boolean | undefined, account?: { __typename?: 'Account', id: string, name: string, status: AccountStatus, createdTime: string } | undefined };

export type GqlAccountFragment = { __typename?: 'Account', id: string, name: string, status: AccountStatus, createdTime: string };

export type GqlBookingFragment = { __typename?: 'Booking', id: string, externalBookingNumber: string, steamshipLine: SteamshipLine, originPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined, destinationPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined };

export type GqlTransportLocationFragment = { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined };

export type GqlBookingContainerPreviewFragment = { __typename?: 'BookingContainer', id: string, shippingContainerNumber: string, commodityCategoryDisplayString: string, stock?: { __typename?: 'BookingContainerStock', mass: number } | undefined, images?: Array<{ __typename?: 'BookingContainerImage', id: string, imageUrl: string, type: BookingContainerImageType, boundingBoxes?: { __typename?: 'BoundingBoxes', boundingBoxes: Array<Array<{ __typename?: 'Point', x: number, y: number }>> } | undefined }> | undefined, booking?: { __typename?: 'Booking', id: string, externalBookingNumber: string, steamshipLine: SteamshipLine, originPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined, destinationPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined } | undefined };

export type GqlBookingContainerImageFragment = { __typename?: 'BookingContainerImage', id: string, imageUrl: string, type: BookingContainerImageType, boundingBoxes?: { __typename?: 'BoundingBoxes', boundingBoxes: Array<Array<{ __typename?: 'Point', x: number, y: number }>> } | undefined };

export type GqlBoundingBoxesFragment = { __typename?: 'BoundingBoxes', boundingBoxes: Array<Array<{ __typename?: 'Point', x: number, y: number }>> };

export type BookingContainersQueryVariables = Exact<{
  input: BookingContainerConnectionInput;
}>;


export type BookingContainersQuery = { __typename?: 'Query', bookingContainers: { __typename?: 'BookingContainerConnection', nodes: Array<{ __typename?: 'BookingContainer', id: string, shippingContainerNumber: string, commodityCategoryDisplayString: string, stock?: { __typename?: 'BookingContainerStock', mass: number } | undefined, images?: Array<{ __typename?: 'BookingContainerImage', id: string, imageUrl: string, type: BookingContainerImageType, boundingBoxes?: { __typename?: 'BoundingBoxes', boundingBoxes: Array<Array<{ __typename?: 'Point', x: number, y: number }>> } | undefined }> | undefined, booking?: { __typename?: 'Booking', id: string, externalBookingNumber: string, steamshipLine: SteamshipLine, originPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined, destinationPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined } | undefined } | undefined>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } };

export type BookingContainerQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type BookingContainerQuery = { __typename?: 'Query', bookingContainer: { __typename?: 'BookingContainer', id: string, shippingContainerNumber: string, commodityCategoryDisplayString: string, stock?: { __typename?: 'BookingContainerStock', mass: number } | undefined, images?: Array<{ __typename?: 'BookingContainerImage', id: string, imageUrl: string, type: BookingContainerImageType, boundingBoxes?: { __typename?: 'BoundingBoxes', boundingBoxes: Array<Array<{ __typename?: 'Point', x: number, y: number }>> } | undefined }> | undefined, booking?: { __typename?: 'Booking', id: string, externalBookingNumber: string, steamshipLine: SteamshipLine, originPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined, destinationPort?: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined } | undefined } | undefined } };

export type GqlPageInfoFragment = { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined };

export type GqlMoneyFragment = { __typename?: 'Money', amount: string, currency: string };

export type GqlPointFragment = { __typename?: 'Point', x: number, y: number };

export type GenerateDocumentQueryVariables = Exact<{
  input: GenerateDocumentInput;
}>;


export type GenerateDocumentQuery = { __typename?: 'Query', generateDocument: { __typename?: 'GenerateDocumentResponse', filename: string, url?: string | undefined } };

export type GqlGenerateDocumentResponseFragment = { __typename?: 'GenerateDocumentResponse', filename: string, url?: string | undefined };

export type FormOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FormOptionsQuery = { __typename?: 'Query', formOptions: { __typename?: 'FormOptions', commodityCategories: Array<{ __typename?: 'FormOption', id: string, description: string, detailDescription?: string | undefined, url?: string | undefined }>, ports: Array<{ __typename?: 'FormOption', id: string, description: string, detailDescription?: string | undefined, url?: string | undefined }>, steamshipLines: Array<{ __typename?: 'FormOption', id: string, description: string, detailDescription?: string | undefined, url?: string | undefined }> } };

export type GqlFormOptionsFragment = { __typename?: 'FormOptions', commodityCategories: Array<{ __typename?: 'FormOption', id: string, description: string, detailDescription?: string | undefined, url?: string | undefined }>, ports: Array<{ __typename?: 'FormOption', id: string, description: string, detailDescription?: string | undefined, url?: string | undefined }>, steamshipLines: Array<{ __typename?: 'FormOption', id: string, description: string, detailDescription?: string | undefined, url?: string | undefined }> };

export type GqlFormOptionFragment = { __typename?: 'FormOption', id: string, description: string, detailDescription?: string | undefined, url?: string | undefined };

export type OrdersQueryVariables = Exact<{
  input: OrderConnectionInput;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', nodes: Array<{ __typename?: 'Order', id: string, status: OrderStatus, externalNumber: string, containerCount: number, completeContainerCount: number, incoterm: Incoterm, postedAt: string, destination: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined }, quotedTotal: { __typename?: 'Money', amount: string, currency: string }, actualTotal?: { __typename?: 'Money', amount: string, currency: string } | undefined } | undefined> } };

export type GqlOrderFragment = { __typename?: 'Order', id: string, status: OrderStatus, externalNumber: string, containerCount: number, completeContainerCount: number, incoterm: Incoterm, postedAt: string, destination: { __typename?: 'TransportLocation', id: string, name: string, countryCode?: string | undefined }, quotedTotal: { __typename?: 'Money', amount: string, currency: string }, actualTotal?: { __typename?: 'Money', amount: string, currency: string } | undefined };

export const GqlAccountFragmentDoc = gql`
    fragment GqlAccount on Account {
  id
  name
  status
  createdTime
}
    `;
export const GqlUserFragmentDoc = gql`
    fragment GqlUser on User {
  id
  email
  name
  phone
  createdTime
  status
  accountDeletionRequested
  account {
    ...GqlAccount
  }
}
    ${GqlAccountFragmentDoc}`;
export const GqlPointFragmentDoc = gql`
    fragment GqlPoint on Point {
  x
  y
}
    `;
export const GqlBoundingBoxesFragmentDoc = gql`
    fragment GqlBoundingBoxes on BoundingBoxes {
  boundingBoxes {
    ...GqlPoint
  }
}
    ${GqlPointFragmentDoc}`;
export const GqlBookingContainerImageFragmentDoc = gql`
    fragment GqlBookingContainerImage on BookingContainerImage {
  id
  imageUrl
  type
  boundingBoxes {
    ...GqlBoundingBoxes
  }
}
    ${GqlBoundingBoxesFragmentDoc}`;
export const GqlTransportLocationFragmentDoc = gql`
    fragment GqlTransportLocation on TransportLocation {
  id
  name
  countryCode
}
    `;
export const GqlBookingFragmentDoc = gql`
    fragment GqlBooking on Booking {
  id
  externalBookingNumber
  originPort {
    ...GqlTransportLocation
  }
  destinationPort {
    ...GqlTransportLocation
  }
  steamshipLine
}
    ${GqlTransportLocationFragmentDoc}`;
export const GqlBookingContainerPreviewFragmentDoc = gql`
    fragment GqlBookingContainerPreview on BookingContainer {
  id
  shippingContainerNumber
  commodityCategoryDisplayString
  stock {
    mass
  }
  images {
    ...GqlBookingContainerImage
  }
  booking {
    ...GqlBooking
  }
}
    ${GqlBookingContainerImageFragmentDoc}
${GqlBookingFragmentDoc}`;
export const GqlPageInfoFragmentDoc = gql`
    fragment GqlPageInfo on PageInfo {
  hasNextPage
  endCursor
}
    `;
export const GqlGenerateDocumentResponseFragmentDoc = gql`
    fragment GqlGenerateDocumentResponse on GenerateDocumentResponse {
  filename
  url
}
    `;
export const GqlFormOptionFragmentDoc = gql`
    fragment GqlFormOption on FormOption {
  id
  description
  detailDescription
  url
}
    `;
export const GqlFormOptionsFragmentDoc = gql`
    fragment GqlFormOptions on FormOptions {
  commodityCategories {
    ...GqlFormOption
  }
  ports {
    ...GqlFormOption
  }
  steamshipLines {
    ...GqlFormOption
  }
}
    ${GqlFormOptionFragmentDoc}`;
export const GqlMoneyFragmentDoc = gql`
    fragment GqlMoney on Money {
  amount
  currency
}
    `;
export const GqlOrderFragmentDoc = gql`
    fragment GqlOrder on Order {
  id
  status
  externalNumber
  containerCount
  completeContainerCount
  destination {
    ...GqlTransportLocation
  }
  incoterm
  quotedTotal {
    ...GqlMoney
  }
  actualTotal {
    ...GqlMoney
  }
  postedAt
}
    ${GqlTransportLocationFragmentDoc}
${GqlMoneyFragmentDoc}`;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    ...GqlUser
  }
}
    ${GqlUserFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export function useGetCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<typeof useGetCurrentUserSuspenseQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const BookingContainersDocument = gql`
    query BookingContainers($input: BookingContainerConnectionInput!) {
  bookingContainers(input: $input) {
    nodes {
      ...GqlBookingContainerPreview
    }
    pageInfo {
      ...GqlPageInfo
    }
  }
}
    ${GqlBookingContainerPreviewFragmentDoc}
${GqlPageInfoFragmentDoc}`;

/**
 * __useBookingContainersQuery__
 *
 * To run a query within a React component, call `useBookingContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingContainersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingContainersQuery(baseOptions: Apollo.QueryHookOptions<BookingContainersQuery, BookingContainersQueryVariables> & ({ variables: BookingContainersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingContainersQuery, BookingContainersQueryVariables>(BookingContainersDocument, options);
      }
export function useBookingContainersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingContainersQuery, BookingContainersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingContainersQuery, BookingContainersQueryVariables>(BookingContainersDocument, options);
        }
export function useBookingContainersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookingContainersQuery, BookingContainersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookingContainersQuery, BookingContainersQueryVariables>(BookingContainersDocument, options);
        }
export type BookingContainersQueryHookResult = ReturnType<typeof useBookingContainersQuery>;
export type BookingContainersLazyQueryHookResult = ReturnType<typeof useBookingContainersLazyQuery>;
export type BookingContainersSuspenseQueryHookResult = ReturnType<typeof useBookingContainersSuspenseQuery>;
export type BookingContainersQueryResult = Apollo.QueryResult<BookingContainersQuery, BookingContainersQueryVariables>;
export const BookingContainerDocument = gql`
    query BookingContainer($id: String!) {
  bookingContainer(id: $id) {
    ...GqlBookingContainerPreview
  }
}
    ${GqlBookingContainerPreviewFragmentDoc}`;

/**
 * __useBookingContainerQuery__
 *
 * To run a query within a React component, call `useBookingContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingContainerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingContainerQuery(baseOptions: Apollo.QueryHookOptions<BookingContainerQuery, BookingContainerQueryVariables> & ({ variables: BookingContainerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingContainerQuery, BookingContainerQueryVariables>(BookingContainerDocument, options);
      }
export function useBookingContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingContainerQuery, BookingContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingContainerQuery, BookingContainerQueryVariables>(BookingContainerDocument, options);
        }
export function useBookingContainerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookingContainerQuery, BookingContainerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookingContainerQuery, BookingContainerQueryVariables>(BookingContainerDocument, options);
        }
export type BookingContainerQueryHookResult = ReturnType<typeof useBookingContainerQuery>;
export type BookingContainerLazyQueryHookResult = ReturnType<typeof useBookingContainerLazyQuery>;
export type BookingContainerSuspenseQueryHookResult = ReturnType<typeof useBookingContainerSuspenseQuery>;
export type BookingContainerQueryResult = Apollo.QueryResult<BookingContainerQuery, BookingContainerQueryVariables>;
export const GenerateDocumentDocument = gql`
    query GenerateDocument($input: GenerateDocumentInput!) {
  generateDocument(input: $input) {
    ...GqlGenerateDocumentResponse
  }
}
    ${GqlGenerateDocumentResponseFragmentDoc}`;

/**
 * __useGenerateDocumentQuery__
 *
 * To run a query within a React component, call `useGenerateDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateDocumentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateDocumentQuery(baseOptions: Apollo.QueryHookOptions<GenerateDocumentQuery, GenerateDocumentQueryVariables> & ({ variables: GenerateDocumentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateDocumentQuery, GenerateDocumentQueryVariables>(GenerateDocumentDocument, options);
      }
export function useGenerateDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateDocumentQuery, GenerateDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateDocumentQuery, GenerateDocumentQueryVariables>(GenerateDocumentDocument, options);
        }
export function useGenerateDocumentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerateDocumentQuery, GenerateDocumentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateDocumentQuery, GenerateDocumentQueryVariables>(GenerateDocumentDocument, options);
        }
export type GenerateDocumentQueryHookResult = ReturnType<typeof useGenerateDocumentQuery>;
export type GenerateDocumentLazyQueryHookResult = ReturnType<typeof useGenerateDocumentLazyQuery>;
export type GenerateDocumentSuspenseQueryHookResult = ReturnType<typeof useGenerateDocumentSuspenseQuery>;
export type GenerateDocumentQueryResult = Apollo.QueryResult<GenerateDocumentQuery, GenerateDocumentQueryVariables>;
export const FormOptionsDocument = gql`
    query FormOptions {
  formOptions {
    ...GqlFormOptions
  }
}
    ${GqlFormOptionsFragmentDoc}`;

/**
 * __useFormOptionsQuery__
 *
 * To run a query within a React component, call `useFormOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormOptionsQuery(baseOptions?: Apollo.QueryHookOptions<FormOptionsQuery, FormOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormOptionsQuery, FormOptionsQueryVariables>(FormOptionsDocument, options);
      }
export function useFormOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormOptionsQuery, FormOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormOptionsQuery, FormOptionsQueryVariables>(FormOptionsDocument, options);
        }
export function useFormOptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormOptionsQuery, FormOptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormOptionsQuery, FormOptionsQueryVariables>(FormOptionsDocument, options);
        }
export type FormOptionsQueryHookResult = ReturnType<typeof useFormOptionsQuery>;
export type FormOptionsLazyQueryHookResult = ReturnType<typeof useFormOptionsLazyQuery>;
export type FormOptionsSuspenseQueryHookResult = ReturnType<typeof useFormOptionsSuspenseQuery>;
export type FormOptionsQueryResult = Apollo.QueryResult<FormOptionsQuery, FormOptionsQueryVariables>;
export const OrdersDocument = gql`
    query Orders($input: OrderConnectionInput!) {
  orders(input: $input) {
    nodes {
      ...GqlOrder
    }
  }
}
    ${GqlOrderFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables> & ({ variables: OrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export function useOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersSuspenseQueryHookResult = ReturnType<typeof useOrdersSuspenseQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;