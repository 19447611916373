import { styled } from "styled-components"
import { BorderRadius, Color, Font, FontSize } from "./Generic"
import { IconButton } from "@mui/material"

//
// Standard (Contained)
//

export const Button2 = styled.button`
    font-family: ${Font.Black};
    font-size: ${FontSize._2};
    text-transform: none; !important;
    border: none;
    border-radius: ${BorderRadius};
    color: #fff;
    background-color: #000;
`

export const Button3 = styled(Button2)`
    font-size: ${FontSize._4};
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
`
export const Button4 = styled(Button2)`
    font-size: ${FontSize._4};
`

export const ButtonClear = styled(Button3)`
    background-color: #fff;
    color: #000;
`

//
// Search Button
//

export const SearchButton = styled(IconButton)`
    background-color: black !important;
    color: white !important;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 28px !important;
    min-width: 0;
    margin-right: -5px !important;
`
//
// Outlined
//

const OutlinedButtonStyle = `
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    padding: 5px 15px 5px 15px;
`

export const OutlinedButton2 = styled(Button2)`
    ${OutlinedButtonStyle}
`

export const OutlinedButton3 = styled(Button3)`
    ${OutlinedButtonStyle}
`

export const OutlinedButton4 = styled(Button4)`
    ${OutlinedButtonStyle}
`

//
// Text
//

// When style is extracted to a variable changes are not reflected in the styled component until page reload
const TextButtonStyle = `
    color: #000;
    background-color: transparent;
    width: fit-content;
    &:disabled {
        color: #aaa;
    }
`
// white-space: nowrap;

export const TextButton2 = styled(Button2)`
    ${TextButtonStyle};
`

export const TextButton3 = styled(Button3)`
    ${TextButtonStyle}
`

export const TextButton4 = styled(Button4)`
    ${TextButtonStyle}
`
