import { BasePage } from "../BasePage/BasePage"
import { LabelBlack1 } from "../Styles/Labels"
import { FlexRowMatchParent } from "../Styles/Styles"
import { OrdersView } from "./OrdersView"

export function OrdersPage() {
    return (
        <BasePage>
            <FlexRowMatchParent>
                <LabelBlack1 style={{ marginBottom: 25 }}>Your orders</LabelBlack1>
            </FlexRowMatchParent>
            <FlexRowMatchParent>
                <OrdersView />
            </FlexRowMatchParent>
        </BasePage>
    )
}
