import { constants } from "./Constants"

export function getFirebaseConfig() {
    if (constants.environment === "prod") {
        return FirebaseConfigProd
    } else {
        return FirebaseConfigDev
    }
}

const FirebaseConfigProd = {
    apiKey: "AIzaSyCSFWzvjedlMUcDH_4_PK_Jli45OsoDnG0",
    authDomain: "marketplace-cf3ae.firebaseapp.com",
    projectId: "marketplace-cf3ae",
    storageBucket: "marketplace-cf3ae.appspot.com",
    messagingSenderId: "476748563350",
    appId: "1:476748563350:web:08ef07d02e4c29efb5d3b7",
    measurementId: "G-48WWWLKDRR",
}

const FirebaseConfigDev = {
    apiKey: "AIzaSyDWUcnP85G4aprnsZMAo8FXKauV_7UFPOQ",
    authDomain: "cyclops-dev.firebaseapp.com",
    projectId: "cyclops-dev",
    storageBucket: "cyclops-dev.appspot.com",
    messagingSenderId: "674660014928",
    appId: "1:674660014928:web:1a74f62132107d6475aa55",
}
