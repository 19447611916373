import { ContainerCardMedia, ContainerCardMediaTintOverlay } from "../Styles/ContainerCard"
import React, { useEffect } from "react"
import { GqlBookingContainerImageFragment } from "../../../generated/graphql/types"
import ComputerVisionDrawing from "./ComputerVisionDrawing"
import useMeasure from "react-use-measure"

export const ContainerImage = ({ image }: { image?: GqlBookingContainerImageFragment }) => {
    const [ref, bounds] = useMeasure()

    return (
        <>
            <ContainerCardMediaTintOverlay />
            <ContainerCardMedia ref={ref} image={image ? image.imageUrl : "dafaultMarketingImage.png"} title="Container Photo" />
            <ContainerDrawingView height={bounds.height} width={bounds.width} image={image} />
        </>
    )
}

const ContainerDrawingView = ({ height, width, image }: { height: number; width: number; image?: GqlBookingContainerImageFragment }) => {
    const elementId = image ? image.id : "na"

    useEffect(() => {
        const drawing = new ComputerVisionDrawing()
        drawing.clear(elementId)
        const points = image?.boundingBoxes?.boundingBoxes
        if (points !== undefined) {
            drawing.drawMany(points, height, width, elementId)
            return
        }
    }, [width, image])

    return <canvas style={{ position: "absolute", top: 0 }} height={height} width={width} id={elementId} />
}
