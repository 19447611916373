import { Box, Stack } from "@mui/material"
import { Label3, LabelBlack2 } from "../Styles/Labels"
import { SpacerV64 } from "../Styles/Spacer"

export const InfoText = ({ title, text }: { title: string; text: string }) => (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="250px">
        <Stack alignItems="center">
            <SpacerV64 />
            <LabelBlack2>{title}</LabelBlack2>
            <Label3>{text}</Label3>
        </Stack>
    </Box>
)
