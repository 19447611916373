export default class ArrayUtils {
    static isNullOrEmpty = <T>(array?: Array<T>) => {
        return array === undefined || array === null || array.length === 0
    }

    static isEmpty<T>(array: ReadonlyArray<T>) {
        return array.length === 0
    }

    static isNotEmpty<T>(array: Array<T>) {
        return array.length !== 0
    }

    static distinct(array: string[]): string[] {
        return Array.from(new Set(array))
    }

    static distinct2<T>(array: T[], keyFunction: (element: T) => string): T[] {
        const map = new Map<string, T>()
        for (const e of array) {
            map.set(keyFunction(e), e)
        }

        return Array.from(map.values())
    }

    static distinct3<T>(array: T[], keyField: string): T[] {
        const map = new Map<string, T>()
        for (const e of array) {
            // @ts-ignore
            map.set(e[keyField], e)
        }

        return Array.from(map.values())
    }

    static split<T>(array: Array<T>, chunkSize: number): Array<T>[] {
        if (chunkSize <= 0) throw new Error("'chunkSize' must be greater than 0")

        const slices: Array<T>[] = []
        for (let i = 0; i < array.length; i += chunkSize) {
            slices.push(array.slice(i, i + chunkSize))
        }
        return slices
    }

    // Same as map() but removes undefined result values
    static compactMap<T, U>(array: T[], callbackFn: (value: T, index: number, array: T[]) => U | undefined | null): U[] {
        const mapped = array.map(callbackFn)
        return mapped.filter((value) => value != undefined) as U[]
    }

    static noUndefineds<T>(array: (T | undefined | null)[]): T[] {
        return array.filter((value) => value != undefined) as T[]
    }
}
