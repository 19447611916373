import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User as FIRUser } from "firebase/auth"
import ArrayUtils from "../../CommonRef/utils/ArrayUtils"
import StringUtils from "../../CommonRef/utils/StringUtils"
// import {Business, BusinessMember, User} from "../../CommonRef/Firebase/GeneratedDataModels/Firestore"
import { AuthPresentationState } from "../../Store/AuthStore"
import store from "../../Store/Store"
import { components } from "../Components"
import Logger from "../../Utils/Logger"
import AuthenticationRepository from "./AuthenticationRepository"
// import {createBusiness, createBusinessOwner} from "../../Types/Defaults"
import { persistentStorage } from "../PersistentStorage"
import { constants } from "../../Config/Constants"
import { accountOperations } from "../../Graphql/Operations/AccountOperations"

const log = new Logger("Authentication")

export default class Authentication {
    // private unsubscribes: Unsubscribe[] = []
    private repository = new AuthenticationRepository()

    constructor() {
        console.log("Authentication")
    }

    // Restore previous user, before we have a callback from the firabase about the login status. Callback is not immediate, it takes 30-90ms to fire.
    // Listen for authentication state changes.
    init() {
        const gqlUser = persistentStorage.currentGqlUser
        if (gqlUser) {
            store.auth.user = gqlUser
        }
        console.log("Authentication.init") // Keep it as console.log()
        onAuthStateChanged(components.firebase.auth, (firUser) => {
            this.onAuthStateChanged(firUser)
        })
    }

    //
    // Start sign-in process (step 0)
    //

    async signInWithGoogle() {
        const result = await signInWithPopup(components.firebase.auth, new GoogleAuthProvider())
        const user = result.user
        log.d("signInWithGoogle", `Google sign is finished with user: ${user.email}`)
    }

    signInEmulators(email: string) {
        // this.repository.signInWithEmailAndPassword(email, "#12345678!")
    }

    // First time called with the current user, i.e. when this callback is fired, we already know if we are logged in or not.
    // Before this callback is fired, we can't know if we are logged in or not, becase firebase.currentUser is null.
    private onAuthStateChanged(firUser: FIRUser | null) {
        log.d("onAuthStateChanged", `User: ${firUser?.uid} ${firUser?.email}`)

        if (!firUser) {
            // If we were logged in before, but now we are not (firUser is null) - sign out
            this.signOutImpl(true, "onAuthStateChanged: No user")
            return
        }

        if (StringUtils.isNullOrEmpty(firUser.email)) {
            this.signOutImpl(true, "onAuthStateChanged: No email")
            return
        }

        this.signInWithFirebaseUser(firUser.uid, firUser.email!).then()
    }

    private async signInWithFirebaseUser(uid: string, email: string) {
        log.d("signInWithFirebaseUser", `uid: ${uid}, email: ${email}`)
        try {
            const user = await accountOperations.getCurrentUser()
            persistentStorage.currentGqlUser = user ?? null
            store.auth.user = user
        } catch (error) {
            this.errorAndSignOut("signInWithFirebaseUser", error as Error)
        }
    }

    // private async startSignIn(email: string, userId: string) {
    //     if (store.auth.userId === userId) {
    //         // TODO check if this is happening
    //         log.e("startSignIn", "Already started signing-in")
    //         return
    //     }
    //     log.d("startSignIn", "starting sign")
    //
    //     store.auth.userId = userId
    //     return await this.signInWithUser(email, userId)
    // }

    /**
     * Sign-in step 1
     */
    // private async signInWithUser(user: User) {
    //     log.d("signInWithUser", "")
    //     store.auth.user = user
    //     try {
    //         // Get business members
    //
    //         const isUserGlobalAdmin = await firestore.globalAdmin.isUserGlobalAdmin(user.id)
    //         store.auth.isGlobalAdmin = isUserGlobalAdmin
    //
    //         let businessMembers = await firestore.businessMember.getUserBusinessMembers(user.id)
    //         let businessMember: BusinessMember | undefined
    //
    //         // If user has no business - create business and business member.
    //         // It is normal for GlobalAdmin to not be member of any business on the first run. No business&member creation needed.
    //         if (ArrayUtils.isEmpty(businessMembers) && !isUserGlobalAdmin) {
    //             const business = createBusiness(user)
    //             await firestore.business.set(business)
    //             businessMember = createBusinessOwner(user, business)
    //             await firestore.businessMember.set(businessMember)
    //             businessMembers = [businessMember]
    //         }
    //         store.auth.businessMembers = businessMembers
    //
    //         // Get businesses
    //
    //         let businesses: Business[]
    //         if (isUserGlobalAdmin) {
    //             businesses = await firestore.business.getAllBusinesses()
    //         } else {
    //             const businessIds = businessMembers.map((member) => member.business_id)
    //             businesses = await firestore.business.getBusinesses(businessIds)
    //             if (ArrayUtils.isEmpty(businesses)) {
    //                 throw new Error("No business assigned to a user")
    //             }
    //         }
    //         store.auth.businesses = businesses
    //
    //         // For new user session, try to sign-in with the previous signed-in business
    //         const lastSignInBusinessId = PersistentStorage.lastSignInBusinessId
    //         if (lastSignInBusinessId) {
    //             const business = businesses.find((biz) => biz.id === lastSignInBusinessId)
    //             const businessMember = businessMembers.find((member) => member.business_id === lastSignInBusinessId)
    //             if (business && businessMember) {
    //                 this.signInWithBusiness(business.id).then()
    //                 return
    //             }
    //         }
    //
    //         if (businesses.length > 1) {
    //             // Multiple businesses - proceed to business picker form
    //             store.auth.presentationState = AuthPresentationState.PickBusiness
    //         } else {
    //             // Single business - proceed to signInWithBusiness()
    //             this.signInWithBusiness(businesses[0].id).then()
    //         }
    //     } catch (error) {
    //         this.errorAndSignOut("signInWithUser", error)
    //     }
    // }

    /**
     * Sign-in step 2
     */
    async signInWithBusiness(businessId: string) {
        // log.d("signInWithBusiness", businessId)
        // PersistentStorage.lastSignInBusinessId = businessId
        //
        // // Set business and business member
        //
        // const businesses = store.auth.businesses
        // const business = businesses.find((biz) => biz.id === businessId)!
        // store.auth.business = business
        // const businessMembers = store.auth.businessMembers
        // let businessMember = businessMembers.find((member) => member.business_id === businessId)
        //
        // // At this point, if GlobalAdmin is not a member of the selected business, create business member.
        // if (businessMember === undefined && store.auth.isGlobalAdmin) {
        //     businessMember = createBusinessOwner(store.auth.user!, business)
        //     await firestore.businessMember.set(businessMember)
        // }
        //
        // store.auth.businessMember = businessMember
        //
        // // After everything is fetched - move app into loggedIn state
        //
        // store.auth.presentationState = AuthPresentationState.LoggedIn
        //
        // // TODO (but not here)
        // // components.commodity_groups.listenForCommodityGroups()
        //
        // // Subscribe to updates
        //
        // // TODO
        // // log.d(`Authentication.signInWithBusiness unsubscribes: ${this.unsubscribes.length}`)
        // // this.unsubscribeThis() // Just in case
        // //
        // // const user = store.getState().authentication.user!
        // // this.listenAuthenticationData(user, user.id, business.id)
        //
        // components.timbereye.startListeners(business.id)
    }

    // changeBusiness() {
    //     log.d("Authentication.changeBusiness")
    //     store.dispatch(changeBusiness())
    //     this.unsubscribeAll()
    // }

    signOut() {
        this.signOutImpl(true, "signOut")
    }

    private signOutImpl(signOutUser: boolean, reason: string) {
        log.d("signOutImpl", `signOut: ${reason}`)
        signOut(components.firebase.auth).catch((error: Error) => {
            log.e("signOutImpl", error)
        })
        store.auth.reset()
        persistentStorage.currentGqlUser = null
    }

    // private listenAuthenticationData(user: User, userId: string, businessId: string) {
    //     // Listen for business member
    //
    //     let unsubscribe = firebase.business_members.read.listenForUserBusinessMembers(user, (businessMembers: BusinessMember[]) => {
    //         log.d(`Authentication.listenAuthenticationData business members: ${businessMembers.length}`)
    //         if (ArrayUtils.isEmpty(businessMembers)) {
    //             this.errorAndSignOut("Business member is gone 1")
    //         }
    //         let businessMember = businessMembers.find((member) => member.business_id === businessId)
    //         businessMember ? store.dispatch(setBusinessMember(businessMember)) : this.errorAndSignOut("Business member is gone 2")
    //     })
    //     this.unsubscribes.push(unsubscribe)
    //
    //     // Listen for open zones
    //
    //     unsubscribe = firebase.commodity_group.read.listenForOpenZones(businessId, (openZones: CommodityGroup[]) => {
    //         if (ArrayUtils.isEmpty(openZones)) {
    //             log.d("Authentication.listenAuthenticationData no open zones")
    //             return
    //         }
    //         let businessMember = store.getState().authentication.business_member!
    //         let zones = Authentication.getCurrentBusinessMemberOpenZones(businessMember, openZones)
    //         log.d(`Authentication.listenAuthenticationData open zones: ${zones.length}`)
    //         store.dispatch(setCurrentBusinessMemberOpenZones(zones))
    //     })
    //     this.unsubscribes.push(unsubscribe)
    //
    //     // Listen for business
    //
    //     unsubscribe = firebase.business.listenForBusiness(businessId, (businesses: Business[]) => {
    //         log.d(`Authentication.listenAuthenticationData businesses: ${businesses.length}`)
    //         if (ArrayUtils.isEmpty(businesses)) {
    //             this.errorAndSignOut("Business is gone 1")
    //         }
    //         let business = businesses.find((biz) => biz.id === businessId)
    //         business ? store.dispatch(setBusiness(businesses[0])) : this.errorAndSignOut("Business is gone 2")
    //     })
    //     this.unsubscribes.push(unsubscribe)
    //
    //     // Listen for user settings
    //
    //     unsubscribe = firebase.settings.listenForUserSettings(userId, (settings?: UserSettings) => {
    //         log.d(`Authentication.listenAuthenticationData user settings: ${settings}`)
    //         store.dispatch(setUserSettings(settings))
    //     })
    //     this.unsubscribes.push(unsubscribe)
    //
    //     // Listen for business settings
    //
    //     unsubscribe = firebase.businessSettings.listenForBusinessSettings(businessId, (settings?: BusinessSettings) => {
    //         log.d(`Authentication.listenAuthenticationData business settings: ${settings?.scale}`)
    //         if (settings) {
    //             store.dispatch(setBusinessSettings(settings))
    //         }
    //     })
    //     this.unsubscribes.push(unsubscribe)
    // }
    //
    // private unsubscribeAll() {
    //     components.commodity_groups.removeAllUnsubscribes()
    //     this.unsubscribeThis()
    // }
    //
    // private unsubscribeThis() {
    //     for (let unsubscribe of this.unsubscribes) {
    //         unsubscribe()
    //     }
    //     this.unsubscribes = []
    // }

    private errorAndSignOut(reason: string, error: unknown) {
        log.e("errorAndSignOut", `${reason}: ${error}`)
        this.signOutImpl(true, reason)
    }
}
