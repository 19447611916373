import { initializeApp } from "firebase/app"
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth"
import { getFirebaseConfig } from "../Config/FirebaseConfig"
import { constants } from "../Config/Constants"
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export class TimbereyeFirebase {
    readonly auth: Auth

    constructor() {
        console.log("TimbereyeFirebase")
        // Initialize Firebase

        const firebaseApp = initializeApp(getFirebaseConfig())

        this.auth = getAuth(firebaseApp)

        console.log(`FUNCTIONS_EMULATOR: ${process.env.FUNCTIONS_EMULATOR}`)
        console.log(`useFirebaseEmulators: ${constants.useFirebaseEmulators}`)
        if (constants.useFirebaseEmulators) {
            console.log("Use emulators")
            connectAuthEmulator(this.auth, "http://localhost:9099")
        }

        // setLogLevel("debug") // Enable Firebase logging
    }
}

const firebase = new TimbereyeFirebase()

export { firebase }
