import { Box, CircularProgress } from "@mui/material"
import { DataGridPremium, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium"
import { MutableRefObject, useRef, useState } from "react"
import {
    GqlGenerateDocumentResponseFragment,
    GqlMoneyFragment,
    GqlOrderFragment,
    useGenerateDocumentLazyQuery,
    useOrdersQuery
} from "../../../generated/graphql/types"
import ArrayUtils from "../../CommonRef/utils/ArrayUtils"
import { useAccount } from "../../Components/Router/ProtectedRoute"
import Logger from "../../Utils/Logger"
import { formatEnum } from "../../Utils/Utils"
import { InfoText } from "../Common/InfoText"
import { LoadingOverlay } from "../Common/LoadingOverlay"
import { TextButton3 } from "../Styles/Buttons"
import { ZStack, ZStackDiv } from "../Styles/ContainerFeedView"

const log = new Logger("OrdersView")

const pageSize = 100
type OrderDocumentsMap = Map<string, GqlGenerateDocumentResponseFragment[]>

export function OrdersView() {
    //
    // Orders query
    //

    const account = useAccount()

    const { loading, error, data } = useOrdersQuery({
        variables: {
            input: {
                orderConnectionFilters: {
                    accountIds: [account.id],
                },
                connection: {
                    pagination: { first: pageSize },
                    sort: [{ fieldName: "postedAt", direction: "desc" }],
                },
            },
        },
    })

    //
    // Generate documents query
    //

    // Maps orderId to generated documents
    const orderDocumentsMapRef: MutableRefObject<OrderDocumentsMap> = useRef(new Map<string, GqlGenerateDocumentResponseFragment[]>())

    // The orderId that we are currently generating documents for
    const [generatingDocsForOrderId, setGeneratingDocsForOrderId] = useState<string | undefined>(undefined)

    const [generateDocument] = useGenerateDocumentLazyQuery({
        onCompleted: (data) => {
            const orderId = generatingDocsForOrderId
            if (orderId && data.generateDocument.url) {
                orderDocumentsMapRef.current.set(orderId, [data.generateDocument])
            } else {
                log.e("generateDocuments.onCompleted", `No orderId or responses for order "${orderId}"`)
            }
            setGeneratingDocsForOrderId(undefined)
        },
        onError: (error) => {
            log.e("generateDocuments.onError", `Error generating documents for order "${generatingDocsForOrderId}" ${error.message}`)
            setGeneratingDocsForOrderId(undefined)
        },
    })

    function generateDocumentsForOrder(orderId: string) {
        setGeneratingDocsForOrderId(orderId)
        generateDocument({
            variables: {
                input: {
                    objectId: orderId,
                    includeCoverPages: true,
                    withUrl: true,
                    includeDetailedPackingLists: true,
                    type: "ORDER",
                },
            },
        })
    }

    //
    // Render
    //

    if (error) {
        log.e("ContainerFeedView", error)
        return <InfoText title="No Orders Found" text="Some error occurred" />
    }

    const rows: GqlOrderFragment[] = ArrayUtils.noUndefineds(data?.orders?.nodes ?? [])
    if (rows.length === 0 && !loading) {
        return <InfoText title="You don't have any orders" text="" />
    }

    return (
        <Box width="70vw">
            <ZStack>
                <ZStackDiv>
                    <DataGridPremium
                        rows={rows}
                        columns={columns(generatingDocsForOrderId, orderDocumentsMapRef.current, generateDocumentsForOrder)}
                        hideFooter
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "postedAt", sort: "desc" }],
                            },
                        }}
                        disableRowSelectionOnClick
                    />
                </ZStackDiv>
                <LoadingOverlay loading={loading} />
            </ZStack>
        </Box>
    )
}

function columns(generatingDocsForOrderId: string | undefined, orderDocumentsMap: OrderDocumentsMap, generateDocumentsForOrder: (orderId: string) => void) {
    const columns: GridColDef<GqlOrderFragment>[] = [
        { headerName: "Number", field: "externalNumber", flex: 12, sortable: false },
        {
            headerName: "Posted",
            field: "postedAt",
            flex: 12,
            valueGetter: (value, row) => new Date(row.postedAt).toLocaleDateString(),
        },
        {
            headerName: "Status",
            field: "status",
            flex: 9,
            valueGetter: (value, row) => formatEnum(row.status),
        },
        {
            headerName: "Containers",
            field: "containerCount",
            flex: 9,
            valueGetter: (value, row) => `${row.completeContainerCount} / ${row.containerCount}`,
        },
        {
            headerName: "Destination",
            field: "destination",
            flex: 24,
            valueGetter: (value, row) => `${row.destination.name}, ${row.destination.countryCode}`,
        },
        {
            headerName: "Incoterm",
            field: "incoterm",
            description: "This column has an Incoterm value.",
            flex: 20,
            valueGetter: (value, row) => formatEnum(row.incoterm),
        },
        {
            headerName: "Quoted",
            field: "quotedTotal",
            flex: 10,
            valueGetter: (value, row) => displayCurrency(row.quotedTotal),
        },
        {
            headerName: "Actual",
            field: "actualTotal",
            flex: 10,
            valueGetter: (value, row) => displayCurrency(row.actualTotal),
        },
        {
            headerName: "Documents",
            field: "documentation",
            flex: 10,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    // Center the button
                    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                        {documentsButton(params.row.id, generatingDocsForOrderId, orderDocumentsMap, generateDocumentsForOrder)}
                    </Box>
                )
            },
        },
    ]

    return columns
}

function documentsButton(orderId: string, generatingDocsForOrderId: string | undefined, orderDocumentsMap: OrderDocumentsMap, generateDocumentsForOrder: (orderId: string) => void) {
    const url = orderDocumentsMap.get(orderId)?.[0]?.url
    if (url) {
        return <TextButton3 onClick={() => openDocument(url)}>View</TextButton3>
    }

    if (generatingDocsForOrderId === orderId) {
        return <CircularProgress size="1.5rem" />
    } else {
        return (
            <TextButton3 disabled={generatingDocsForOrderId != undefined} onClick={() => generateDocumentsForOrder(orderId)}>
                Generate
            </TextButton3>
        )
    }
}

function displayCurrency(money: GqlMoneyFragment | undefined) {
    if (!money) return ""
    let currencyString = money.currency
    if (currencyString === "USD") {
        currencyString = "$"
    } else if (currencyString === "EUR") {
        currencyString = "€"
    }
    const formattedAmount = Number(money.amount).toLocaleString("en-US")
    return `${currencyString}${formattedAmount}`
}

function openDocument(url: string) {
    window.open(url, "_blank", "noopener,noreferrer")
}
