// import store from "../../Store/Store";
// import ServerFieldsListener from "./Listeners/ServerFieldsListener";
// import CommodityGroupsListener from "./Listeners/CommodityGroupsListener";
import store from "../../Store/Store"

export default class TimberEyeComponent {
    // private readonly serverFields = new ServerFieldsListener()
    // private readonly commodityGroups = new CommodityGroupsListener()

    toggleSidebar() {
        store.timbereye.sidebarCollapsed = !store.timbereye.sidebarCollapsed
    }

    collapseSidebar() {
        store.timbereye.sidebarCollapsed = true
    }

    // startListeners(businessId: string) {
    //     this.serverFields.startListening()
    //     this.commodityGroups.startListening(businessId)
    // }
    //
    // reset() {
    //     this.commodityGroups.stopListening()
    //     this.serverFields.stopListening()
    // }
}
