import { ZStackDivOverlay } from "../Styles/ContainerFeedView"
import { Color } from "../Styles/Generic"
import { StyledLinearProgress } from "../Styles/LinearProgress"

export function LoadingOverlay({ loading }: { loading: boolean }) {
    if (!loading) return null
    return (
        <ZStackDivOverlay>
            <StyledLinearProgress
                sx={{
                    backgroundColor: Color.DarkLightGray,
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: "white",
                    },
                }}
            />
        </ZStackDivOverlay>
    )
}
