import { GqlUserFragment } from "../../generated/graphql/types"
import Logger from "../Utils/Logger"

const log = new Logger("PersistentStorage")

class PersistentStorage {
    get currentGqlUser(): GqlUserFragment | null {
        const json = localStorage.getItem("currentGqlUser")
        if (!json) return null
        try {
            return JSON.parse(json) as GqlUserFragment
        } catch (error) {
            log.e("currentGqlUser", `Error parsing currentGqlUser: ${error}`)
            return null
        }
    }

    set currentGqlUser(gqlUser: GqlUserFragment | null) {
        if (gqlUser) {
            localStorage.setItem("currentGqlUser", JSON.stringify(gqlUser))
        } else {
            localStorage.removeItem("currentGqlUser")
        }
    }
}

export const persistentStorage = new PersistentStorage()
