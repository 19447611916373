import { Navigate } from "react-router-dom"
import { constants } from "../../Config/Constants"
import { platformIsMobile } from "../../Utils/Platform"
import { ContactSalesPage } from "../../View/ContactSalesPage"
import { ContainerPage } from "../../View/Container/ContainerPage"
import { ContainerFeedPage } from "../../View/ContainerFeed/ContainerFeedPage"
import { LogInPage } from "../../View/LogInPage"
import {
    BookingsMarketingPage,
    BusinessMarketingPage,
    CapitalMarketingPage,
    ContainersMarketingPage,
    DocumentationMarketingPage,
    NotificationsMarketingPage,
    OrdersMarketingPage,
    PeopleMarketingPage,
    ProfileMarketingPage,
    SupportMarketingPage,
} from "../../View/MarketingPages"
import { MobileHomePage } from "../../View/Mobile/MobileHomePage"
import { OrdersPage } from "../../View/Orders/OrdersPage"
import { RetoolView } from "../../View/Retool/RetoolView"
import { AccountProtectedRoute } from "./ProtectedRoute"

export enum RoutePath {
    marketingProfile = "/profile",
    marketingPeople = "/people",
    marketingOrders = "/orders",
    marketingBookings = "/bookings",
    marketingContainers = "/containers",
    marketingDocuments = "/documents",
    marketingNotifications = "/notifications",
    marketingCapital = "/capital",
    marketingBusiness = "/business",
    marketingHelp = "/help",
    contactSales = "/contactSales",
    login = "/login",
    home = "/",
    tracker = "/tracker",
    containerFeed = "/feed",
    container = "/feed/:bookingContainerId",
}

export class MakeRoute {
    static bookingContainer(containerId: string): string {
        return `${RoutePath.containerFeed}/${containerId}`
    }
}

const appRoutes = [
    // Match all i.e. page not found

    {
        path: "*",
        element: <Navigate to={RoutePath.containerFeed} replace />,
    },

    // Home

    {
        path: RoutePath.home,
        element: <Navigate to={RoutePath.containerFeed} replace />,
    },

    // Retool

    {
        path: RoutePath.tracker,
        element: <RetoolView />,
    },

    // Containers

    {
        path: RoutePath.containerFeed,
        element: <ContainerFeedPage />,
    },
    {
        path: RoutePath.container,
        element: <ContainerPage />,
    },

    // Marketing

    {
        path: RoutePath.marketingProfile,
        element: <ProfileMarketingPage />,
    },
    {
        path: RoutePath.marketingPeople,
        element: <PeopleMarketingPage />,
    },
    {
        path: RoutePath.marketingOrders,
        element: <AccountProtectedRoute signedInRoute={<OrdersPage />} signedOutRoute={<OrdersMarketingPage />} />,
    },
    {
        path: RoutePath.marketingBookings,
        element: <BookingsMarketingPage />,
    },
    {
        path: RoutePath.marketingDocuments,
        element: <DocumentationMarketingPage />,
    },
    {
        path: RoutePath.marketingHelp,
        element: <SupportMarketingPage />,
    },
    {
        path: RoutePath.marketingBusiness,
        element: <BusinessMarketingPage />,
    },
    {
        path: RoutePath.marketingCapital,
        element: <CapitalMarketingPage />,
    },
    {
        path: RoutePath.marketingNotifications,
        element: <NotificationsMarketingPage />,
    },
    {
        path: RoutePath.marketingContainers,
        element: <ContainersMarketingPage />,
    },
    {
        path: RoutePath.contactSales,
        element: <ContactSalesPage />,
    },

    // Login

    {
        path: RoutePath.login,
        element: constants.allowSignIn ? <LogInPage /> : <Navigate to={RoutePath.home} replace />,
    },
]

const mobileAppRoutes = [
    // Match all paths
    {
        path: "*",
        element: <MobileHomePage />,
    },
]

export function getAppRoutes() {
    if (platformIsMobile) {
        return mobileAppRoutes
    } else {
        return appRoutes
    }
}
