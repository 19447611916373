import { ApolloProvider } from "@apollo/client"
import { ThemeProvider } from "@mui/material"
import { LicenseInfo } from "@mui/x-license"
import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { components } from "./Components/Components"
import { graphqlClient } from "./Graphql/GraphqlClient"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { timbereyeTheme } from "./View/Styles/Theme"

components.auth.init()
LicenseInfo.setLicenseKey("ea36b97f68f1271e2dd42b1751fd1f43Tz04ODI2NSxFPTE3NDQ0NzMzMDMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y")

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <React.StrictMode>
        <ApolloProvider client={graphqlClient}>
            <BrowserRouter>
                <ThemeProvider theme={timbereyeTheme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
