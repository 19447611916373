import { GetCurrentUserDocument, GetCurrentUserQuery, GetCurrentUserQueryVariables, GqlUserFragment } from "../../../generated/graphql/types"
import { graphqlClient } from "../GraphqlClient"

class AccountOperations {
    async getCurrentUser(): Promise<GqlUserFragment | undefined> {
        const result = await graphqlClient.query<GetCurrentUserQuery, GetCurrentUserQueryVariables>({
            query: GetCurrentUserDocument,
        })
        return result.data?.currentUser
    }
}

export const accountOperations = new AccountOperations()
