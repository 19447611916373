import { FlexColumn, FlexRow, FullscreenFlexRow } from "./Styles/Styles"
import { BasePage } from "./BasePage/BasePage"
import { SpacerH10, SpacerH64, SpacerV5, SpacerV64 } from "./Styles/Spacer"
import { Button3, OutlinedButton3 } from "./Styles/Buttons"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../Components/Router/AppRoutes"
import { Label2, LabelBold0 } from "./Styles/Labels"
import { MarketingImage } from "./Styles/Image"

export const ProfileMarketingPage = () => (
    <MarketingPage
        header="Your commodity buying, managed from a single account."
        text="Simplify orders, bookings, containers, trade documentation and team coordination with a TimberEye account."
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/buying_placeholder.png"}
    />
)

export const PeopleMarketingPage = () => (
    <MarketingPage
        header="Give your team the gift of transparent shipment data."
        text="Say goodbye to spreadsheet headaches and put the system generated data behind every order, booking and container in the hands of your team."
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/team_placeholder.png"}
    />
)

export const OrdersMarketingPage = () => (
    <MarketingPage
        header="Track your commodity orders as they're fulfilled in real time."
        text="We manage orders, ocean freight, trucking, container loading and capital. All from one platform."
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/orders_placeholder.png"}
    />
)

export const BookingsMarketingPage = () => (
    <MarketingPage
        header="At last, the Shipper that handles what’s inside your containers."
        text="We are your direct link with steamship lines AND we track every detail of what goes inside your containers."
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/nvocc_placeholder.png"}
    />
)

export const ContainersMarketingPage = () => (
    <MarketingPage
        header="We audit the contents of every single container that ships."
        text="Our suppliers capture scans, images, and certified scale weights of every container that we ship."
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/audit_placeholder.png"}
    />
)

export const DocumentationMarketingPage = () => (
    <MarketingPage
        header="AI Generated Bills of Lading, Packing Lists  and Invoices."
        text="We produce documents instantaneously as events happen, so you never need to wait for a human administrator. "
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/documents_placeholder.png"}
    />
)

export const NotificationsMarketingPage = () => (
    <MarketingPage
        header="Stay up to date with notifications about your activity."
        text="Find out when containers get loaded, weighed and shipped so you can take action in your business. "
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/notifications_placeholder.png"}
    />
)

export const CapitalMarketingPage = () => (
    <MarketingPage
        header="The most flexible capital partner in all of global trade."
        text="Whether it’s Letters of Credit, Working Capital, or automated payments, we keep supply chains moving. "
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/capitalPartner_placeholder.png"}
    />
)

export const BusinessMarketingPage = () => (
    <MarketingPage
        header="Run your business’s container buying with TimberEye."
        text="TimberEye is your business’s end to end commodity supplier. We handle sourcing, capital and logistics from a single platform."
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/business_placeholder.png"}
    />
)

export const SupportMarketingPage = () => (
    <MarketingPage
        header="Need support? Contact our team with questions at any time."
        text="Our team is available 24 hours a day, 7 days a week on Whatsapp, Email, and Phone. Contact us any time."
        image={"https://storage.googleapis.com/timbereye_public/retool/placeholders/support_placeholder.png"}
    />
)

function MarketingPage({ header, text, image }: { header: string; text: string; image: string }) {
    const navigate = useNavigate()

    return (
        <BasePage>
            <FullscreenFlexRow>
                <SpacerH64 />
                <FlexColumn>
                    <LabelBold0 style={{ marginBottom: "25px" }}>{header}</LabelBold0>
                    <SpacerV5 />
                    <Label2 style={{ marginBottom: "25px" }}>{text}</Label2>
                    <SpacerV5 />
                    <FlexRow>
                        <Button3
                            onClick={() => {
                                // navigate(RoutePath.login)
                                window.location.href = "https://apps.apple.com/us/app/timbereye-buy-containers/id6499224336"
                            }}
                        >
                            Get The App
                        </Button3>
                        <SpacerH10 />
                        <OutlinedButton3
                            onClick={() => {
                                navigate(RoutePath.contactSales)
                            }}
                        >
                            Contact Sales
                        </OutlinedButton3>
                    </FlexRow>
                </FlexColumn>
                <SpacerH64 />
                <MarketingImage src={image} alt="Marketing Image" />
                <SpacerH64 />
            </FullscreenFlexRow>
        </BasePage>
    )
}
