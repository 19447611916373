import { useParams } from "react-router-dom"
import React from "react"
import Logger from "../../Utils/Logger"
import { BasePage } from "../BasePage/BasePage"
import { GqlBookingContainerPreviewFragment, useBookingContainerQuery } from "../../../generated/graphql/types"
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material"
import { FlexRow } from "../Styles/Styles"
import { Label6, LabelBold5 } from "../Styles/Labels"
import { Space } from "../Styles/Spacer"

const log = new Logger("ContainerPage")

export const ContainerPage = () => (
    <BasePage>
        <ContainerView />
    </BasePage>
)

function ContainerView() {
    // Validate args

    const { bookingContainerId } = useParams()
    log.d("bookingContainerId", `${bookingContainerId}`)
    if (!bookingContainerId) {
        return <p>Unknown Container</p>
    }

    //

    const queryInput = { variables: { id: bookingContainerId } }
    const { loading, error, data } = useBookingContainerQuery(queryInput)

    const onBookingContainerClick = (bookingContainerId: string) => {
        // navigate(MakeRoute.bookingContainer(bookingContainerId))
    }

    if (loading) return <p>Loading...</p>
    if (error) {
        log.e("ContainerFeedView", error)
        return <p>Error : {error.message}</p>
    }
    if (!data) {
        return <p>No data</p>
    }

    return <ContainerCard data={data.bookingContainer} onClick={onBookingContainerClick} />
}

function ContainerCard({ data, onClick }: ContainerCardArgs) {
    const image = data.images?.[0]?.imageUrl ?? "dafaultMarketingImage.png"

    return (
        <Card>
            <CardActionArea onClick={() => onClick(data.id)}>
                <CardMedia sx={{ width: 500, height: 500 }} image={image} title="Container Photo" />
                <CardContent>
                    <FlexRow>
                        <LabelBold5>{data.shippingContainerNumber}</LabelBold5>
                        <Space />
                        <LabelBold5>{data.stock?.mass ?? 0}mt</LabelBold5>
                    </FlexRow>
                    <FlexRow>
                        <Label6>{data.commodityCategoryDisplayString}</Label6>
                        <Interpunct />
                        <Label6>BookingNumber</Label6>
                        <Interpunct />
                        <Label6>CMA/CGM</Label6>
                    </FlexRow>
                    <Label6>{"Charleston, South Carolina > Mundra, India"}</Label6>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

const Interpunct = () => <Label6 style={{ marginLeft: "0.25rem", marginRight: "0.25rem" }}>·</Label6>

interface ContainerCardArgs {
    data: GqlBookingContainerPreviewFragment
    onClick: (bookingContainerId: string) => void
}
