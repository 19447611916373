import { useEffect } from "react"
import { useLocation, useNavigate, useRoutes } from "react-router-dom"
import { useSnapshot } from "valtio"
import "./App.css"
import { getAppRoutes, RoutePath } from "./Components/Router/AppRoutes"
import { constants } from "./Config/Constants"
import { AuthPresentationState } from "./Store/AuthStore"
import store from "./Store/Store"
import Logger from "./Utils/Logger"

const log = new Logger("App")

// TODO rm - debug var
let useEffectCount = 0

function App() {
    const authState = useSnapshot(store.auth)
    const navigate = useNavigate()
    const location = useLocation()
    const appRoutes = useRoutes(getAppRoutes())

    // Note: This will render twice in debug mode, because we use StrictMode. In prod it will render only once. https://stackoverflow.com/a/60619061/5035991
    useEffect(() => {
        useEffectCount++
        let maxRenders = 1
        if (constants.useFirebaseEmulators) {
            maxRenders = 2
        }
        if (useEffectCount > maxRenders) {
            log.e("useEffect", `called ${useEffectCount} times`)
            return
        }
    }, [])

    // Redirect based on authentication state
    useEffect(() => {
        log.d("authState changed", `${authState.presentationState}`)
        switch (authState.presentationState) {
            case AuthPresentationState.LoggedOut:
                // navigate(RoutePath.login)
                break
            case AuthPresentationState.LoggedIn:
                // When in the login process, redirect to home; otherwise, stay on current page.
                if (location.pathname === RoutePath.login) {
                    navigate(RoutePath.home)
                }
                break
        }
    }, [authState])

    return appRoutes
}

export default App
