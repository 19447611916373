import React, { createContext, useContext } from "react"
import { useSnapshot } from "valtio"
import { GqlAccountFragment, GqlUserFragment } from "../../../generated/graphql/types"
import store from "../../Store/Store"

export function UserProtectedRoute({ signedInRoute, signedOutRoute }: ProtectedRouteProps) {
    const auth = useSnapshot(store.auth)

    const user = getActiveUser(auth.user)
    if (user == undefined) {
        return signedOutRoute
    }

    return <UserProvider user={user}>{signedInRoute}</UserProvider>
}

export function AccountProtectedRoute({ signedInRoute, signedOutRoute }: ProtectedRouteProps) {
    const auth = useSnapshot(store.auth)

    const user = getActiveUser(auth.user)
    const account = getActiveAccount(user?.account)
    if (user == undefined || account == undefined) {
        return signedOutRoute
    }

    return (
        <UserProvider user={user}>
            <AccountProvider account={account}>{signedInRoute}</AccountProvider>
        </UserProvider>
    )
}

interface ProtectedRouteProps {
    signedInRoute: JSX.Element
    signedOutRoute: JSX.Element
}

function getActiveUser(user?: GqlUserFragment): GqlUserFragment | undefined {
    if (user == undefined || user.status != "ACTIVE") {
        return undefined
    }
    return user
}

function getActiveAccount(account?: GqlAccountFragment): GqlAccountFragment | undefined {
    if (account == undefined || account.status != "ACTIVE") {
        return undefined
    }
    return account
}

//
// UserContext
//

const UserContext = createContext<GqlUserFragment | undefined>(undefined)

function UserProvider({ user, children }: { user: GqlUserFragment; children: React.ReactNode }) {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export function useUser(): GqlUserFragment {
    const user = useContext(UserContext)
    if (!user) {
        throw new Error("useUser must be used within a UserProvider")
    }
    return user
}

//
// AccountContext
//

const AccountContext = createContext<GqlAccountFragment | undefined>(undefined)

function AccountProvider({ account, children }: { account: GqlAccountFragment; children: React.ReactNode }) {
    return <AccountContext.Provider value={account}>{children}</AccountContext.Provider>
}

export function useAccount(): GqlAccountFragment {
    const account = useContext(AccountContext)
    if (!account) {
        throw new Error("useAccount must be used within an AccountProvider")
    }
    return account
}
