import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { constants } from "../Config/Constants"
import { components } from "../Components/Components"

const httpLink = createHttpLink({
    uri: constants.graphqlRequestRootUrl,
})

const authLink = setContext(async (_, { headers }) => {
    const token = await components.firebase.auth.currentUser?.getIdToken()
    if (token == undefined) {
        return headers
    }

    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        },
    }
})

export const graphqlClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})
